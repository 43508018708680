import { CustomButton } from "@Components";
import React from "react";
import "./Buttons.scss";

interface PT {
  onBack?: () => void;
  onSubmit?: () => void;
  backTitle?: string;
  submitTitle?: string;
  disabled?: boolean;
}

const Buttons: React.FC<PT> = ({
  onBack,
  onSubmit,
  backTitle,
  submitTitle,
  disabled,
}) => {
  return (
    <div className="buttons">
      {onBack && (
        <CustomButton
          className="back-button"
          title={backTitle ? backTitle : "Quay lại"}
          onClick={onBack}
        />
      )}
      {onSubmit && (
        <CustomButton
          disabled={disabled}
          title={submitTitle ? submitTitle : "Thanh toán"}
          onClick={onSubmit}
        />
      )}
    </div>
  );
};

export default React.memo(Buttons);
