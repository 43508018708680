import { GenderEnum } from "@Types";
import {
  BookApis,
  BookProperties,
  Character,
  PropertiesPayload,
} from "@Services";
import { ParamEnum } from "@Types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { personalizeActions, personalizeSelector } from "@Redux";

export const useGetBookProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const bookId = params[ParamEnum.book_id] as string;

  const [charactors, setCharactors] = useState<Character[]>();

  const personalizeState = useSelector(personalizeSelector);
  const currenCharacterId = personalizeState.currChar;
  const currentGender = personalizeState.properties.find(
    (item) => item.characterId == currenCharacterId
  )?.gender;

  useEffect(() => {
    if (currentGender && charactors) {
      const currCharacter = charactors.find(
        (item) => item.character == currenCharacterId
      );

      for (const property of currCharacter?.properties || []) {
        if (!!property.optionsWithGender) {
          dispatch(
            personalizeActions.updateProperty({
              characterId: currenCharacterId as string,
              [property.field]:
                property.defaultValueWithGender?.[currentGender as GenderEnum],
            })
          );
        }
      }

      console.log(currentGender);
    }
  }, [currentGender]);

  useEffect(() => {
    const onSuccess = (data: BookProperties) => {
      // call API to get properties for this book
      const charactersList = data.data as Character[];
      setCharactors(charactersList);

      const defaultProps = charactersList.map((char) => {
        const properties = char.properties.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.field]: curr.defaultValue || null,
          }),
          {}
        );
        return {
          characterId: char.character,
          ...properties,
        };
      }) as PropertiesPayload[];

      // set default value to redux
      const defaultChar = data.data?.find((char) => char.index == 1);
      const charInfos = charactersList.map((char) => ({
        index: char.index,
        character: char.character,
        totalStep: char.totalStep,
      }));

      const payload = {
        currStep: 1,
        currCharIndex: 1,
        currChar: defaultChar?.character,
        totalCharCount: data.data?.length || 0,
        totalStep: defaultChar?.totalStep || 0,
        properties: defaultProps,
        charactersInfo: charInfos,
      };
      dispatch(personalizeActions.setDefaultProperties(payload));
    };

    const onErorr = () => {
      toast.error("Get book properties error!!!!");
      navigate("/");
    };
    BookApis.getBookProperties(bookId, onSuccess, onErorr);
  }, []);

  return { charactors };
};
