import { ProductQuery, ProductsResponse, SaleorAppApis } from "@Services";
import { useEffect, useState } from "react";

export const useGetProducts = (query: ProductQuery) => {
  const [products, setProducts] = useState<ProductsResponse>();

  useEffect(() => {
    const onSuccess = (data: ProductsResponse) => {
      setProducts(data);
    };

    SaleorAppApis.getProducts(query, onSuccess);
  }, []);

  return { products };
};
