import React from "react";
import "./MainLogo.scss";
import { CustomImage } from "@Components/CustomImage";
import { useNavigate } from "react-router-dom";
import { MainRouteEnum } from "@Types";

const MainLogo = () => {
  const nav = useNavigate();

  const goHome = () => {
    nav(MainRouteEnum.home);
  };
  return (
    <div className="main-logo" onClick={goHome}>
      <CustomImage className="top-left-logo" />
    </div>
  );
};

export default React.memo(MainLogo);
