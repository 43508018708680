import { DedicatePageType, PreviewBookType } from "@Services";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type PreviewProps = {
  bookId: string | null;
  data: PreviewBookType[];
};

const initialState: PreviewProps = {
  bookId: null,
  data: [],
};

export const previewSlice = createSlice({
  name: "previews",
  initialState,
  reducers: {
    reset: () => initialState,

    setPreviewData: (state, action: PayloadAction<PreviewProps>) => {
      return { ...state, ...action.payload };
    },

    dedicatePage: (state, action: PayloadAction<DedicatePageType>) => {
      const replaceIndex = state.data.findIndex(
        (page) => page.page == action.payload.page
      );
      state.data[replaceIndex] = { ...action.payload, editDedications: true };
    },
  },
});

export const previewReducer = previewSlice.reducer;
export const previewActions = previewSlice.actions;
//
