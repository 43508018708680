import { ScrollToTop } from "@Components/ScrollToTop";
import { MainRoutes } from "@Routes";
import { Route, Routes } from "react-router-dom";
import "./MainLayout.scss";

export const MainLayout = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        {MainRoutes.map((a, index) => (
          <Route key={index} {...a} />
        ))}
      </Routes>
    </>
  );
};
