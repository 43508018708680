import { sliderSelector } from "@Redux";
import { ProductImage } from "@generated";
import React from "react";
import { useSelector } from "react-redux";
import "./MiniImg.scss";

interface Props {
  previewImages: ProductImage[];
}

export const MiniImg: React.FC<Props> = (props: Props) => {
  const sliderState = useSelector(sliderSelector);
  const slickRef = sliderState.slickRef;
  const selectedDemoImg = sliderState.selectedDemoImg;

  const onChange = (index: number) => {
    slickRef.slickGoTo(index);
  };

  return (
    <div className="mini-img-wrap">
      {props.previewImages.map((image, i) => (
        <div
          key={i}
          className={`mini-img ${
            selectedDemoImg === i ? "mini-img-active" : "mini-img-un-active"
          }`}
          onClick={() => {
            onChange(i);
          }}
        >
          <img src={image.url} alt={image.alt as string} />
        </div>
      ))}
    </div>
  );
};
