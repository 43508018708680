import { useLocation } from "react-router-dom";
import React from "react";

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => {
    const params = new URLSearchParams(search);
    console.log("[tessss]", params);
    const queryObject: any = {};
    for (const [key, value] of params.entries() as any) {
      queryObject[key] = value;
    }
    return queryObject;
  }, [search]);
};
