import React from "react";
import Rater from "react-rater";
import "./CustomRating.scss";

interface Props {
  total?: number;
  rating?: number;
  interactive?: boolean;
}

export const CustomRating: React.FC<Props> = (props: Props) => {
  return (
    <Rater
      rating={props.rating}
      total={props.total}
      interactive={props.interactive}
    />
  );
};
