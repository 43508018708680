import { PreviewBookPayload, PropertiesPayload } from "@Services";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type UpdatePropertyPayload = {
  characterId: string;
  [key: string]: any;
};

type UpdatePhasePayload = {
  currStep?: number;
  currCharIndex?: number;
  currChar?: string;
  totalStep?: number;
};

export type CharacterInfo = {
  index: number;
  character: string;
  totalStep: number;
};

export type PersonalizeProps = PreviewBookPayload & {
  currStep: number;
  currCharIndex: number;
  currChar: string | undefined;
  totalCharCount: number | undefined;
  totalStep: number | undefined;
  charactersInfo: CharacterInfo[];
};

const initialState: PersonalizeProps = {
  currStep: 1,
  currCharIndex: 1,
  currChar: undefined,
  totalCharCount: undefined,
  totalStep: undefined,
  properties: [],
  charactersInfo: [],
};

export const personalizeSlice = createSlice({
  name: "personalize",
  initialState,
  reducers: {
    reset: () => initialState,

    setDefaultProperties: (state, action: PayloadAction<PersonalizeProps>) => {
      return { ...state, ...action.payload };
    },

    updateProperty: (state, action: PayloadAction<UpdatePropertyPayload>) => {
      const replaceIndex = state.properties.findIndex(
        (item) => item.characterId == action.payload.characterId
      );
      const { characterId, ...replaceObject } = action.payload;
      const replaceKey = Object.keys(replaceObject)[0];
      state.properties[replaceIndex][replaceKey] = action.payload[replaceKey];
    },

    setCurrentChar: (state, action: PayloadAction<UpdatePhasePayload>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const personalizeReducer = personalizeSlice.reducer;
export const personalizeActions = personalizeSlice.actions;
