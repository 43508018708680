import { CustomImage } from "@Components";
import React from "react";
import "./MenuItem.scss";

interface PT {
  title: string;
  categoryId: string;
  onClick?: (categoryId: string) => void;
}

const MenuItem: React.FC<PT> = ({ title, onClick, categoryId }) => {
  return (
    <>
      <div className="menu-item" onClick={() => onClick?.(categoryId)}>
        <p>{title}</p>
        <CustomImage className="menu-item-down" />
      </div>
    </>
  );
};

export default React.memo(MenuItem);
