import { sliderActions, useAppDispatch } from "@Redux";
import { ProductImage } from "@generated";
import Slider from "react-slick";
import "./ImgCarousel.scss";

interface Props {
  previewImages: ProductImage[];
}

export const ImgCarousel = (props: Props) => {
  console.log("[previewImage]", props.previewImages);
  const dispatch = useAppDispatch();

  const settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) =>
      dispatch(sliderActions.setSelectedDemoImg(next)),
  };

  return (
    <div className="img-carousel">
      <Slider
        ref={(slider) => {
          dispatch(sliderActions.setSlickRef(slider));
        }}
        {...settings}
      >
        {props.previewImages.map((product, i) => (
          <img
            className="img-carousel-item"
            key={i}
            src={product.url}
            alt={product.alt as string}
          />
        ))}
      </Slider>
    </div>
  );
};
