import { useClickOutside } from "@Hooks";
import { GetProductsQuery } from "@generated";
import React, { useEffect, useState } from "react";
import "./SearchDopdown.scss";
import { useNavigate } from "react-router-dom";
import { MainRouteEnum } from "@Types";

type SearchPhase = "result" | "notfound";
interface Props extends GetProductsQuery {
  searchTerm?: string;
}

export const SearchDropdown: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [searchPhase, setSearchPhase] = useState<SearchPhase | null>();

  useEffect(() => {
    if (props.searchTerm) {
      setIsOpen(true);
      const count = props.products?.totalCount;
      if (count && count > 0) {
        setSearchPhase("result");
      } else {
        setSearchPhase("notfound");
      }
    } else {
      setSearchPhase(null);
      setIsOpen(false);
    }
  }, [props]);

  const handleClickOutside = () => {
    setIsOpen(false);
  };
  const { divRef } = useClickOutside(handleClickOutside);

  const onClickProduct = (productId: string) => {
    navigate(`/${MainRouteEnum.detail}/${productId}`);
  };

  const renderItem = () => {
    switch (searchPhase) {
      case "result":
        return (
          <div>
            {props.products?.edges.map((item) => (
              <p
                onClick={() => onClickProduct(item.node.id)}
                className="searh-drop-item"
              >
                {item.node.name}
              </p>
            ))}
          </div>
        );

      case "notfound":
        return <div className="searh-drop-empty">Result not found !!</div>;
      default:
        return <></>;
    }
  };

  return (
    <>
      {isOpen && (
        <div
          ref={divRef}
          className="searh-drop"
          onClick={() => setIsOpen(!isOpen)}
        >
          {renderItem()}
        </div>
      )}
    </>
  );
};
