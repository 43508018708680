import { CustomRadio } from "@Components/CustomRadio";
import { useRenderHelper } from "@Hooks";
import { CharactorProperty, PropertyOptions } from "@Services";
import { GenderEnum, SelectItemType } from "@Types";
import React from "react";
import "./SelectImgForm.scss";
import { FormButtons, RetriveImage } from "@Components";

interface Props extends CharactorProperty {}

export const SelectImgForm: React.FC<Props> = (props: Props) => {
  const {
    bookId,
    queryString,
    activeNext,
    buttonTitle,
    currentGender,
    onChangeValue,
    onNextStep,
    onPrevStep,
  } = useRenderHelper(props);

  const options = props.optionsWithGender
    ? props.optionsWithGender[currentGender as GenderEnum]
    : props.options;

  const renderRadioOptions = (options: PropertyOptions[]) => {
    const minIoUrl = process.env.REACT_APP_MINIO_URL;
    const convert = options.map((option) => ({
      value: option.key,
      label: (
        <div className="select-img-form">
          <img src={`${minIoUrl}/${option.value as string}`} />
        </div>
      ),
    }));

    return convert;
  };

  return (
    <>
      {props?.preview && (
        <div className="preview-character">
          <RetriveImage path={`${bookId}-preview/${queryString}.png`} />
        </div>
      )}
      <div className="custom-form-title">{props.title}</div>
      <div className="custom-form-desc">Please select your {props.title}</div>
      {options && (
        <CustomRadio
          options={
            renderRadioOptions(options as PropertyOptions[]) as SelectItemType[]
          }
          onChange={onChangeValue}
          isImg
        />
      )}
      <FormButtons
        onNext={onNextStep}
        onBack={onPrevStep}
        prevTitle={buttonTitle?.prevTitle as string}
        nextTitle={buttonTitle?.nextTitle as string}
        disabled={!activeNext}
      />
    </>
  );
};
