import { previewActions, previewSelector } from "@Redux";
import {
  BookApis,
  PrevewBookResponse,
  PreviewBookPayload,
  PreviewBookType,
} from "@Services";
import { ParamEnum } from "@Types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

export const usePreviewProduct = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const previewState = useSelector(previewSelector);

  const productId = params[ParamEnum.product_id] as string;
  const bookId = params[ParamEnum.book_id] as string;

  useEffect(() => {
    const onSuccess = (data: PrevewBookResponse) => {
      dispatch(
        previewActions.setPreviewData({
          bookId: bookId,
          data: data.data as PreviewBookType[],
        })
      );
    };

    const payload = { properties: location.state } as PreviewBookPayload;
    BookApis.previewProduct(bookId, payload, onSuccess);
  }, []);

  return { productId, bookId, prevewData: previewState.data };
};
