import React from "react";
import "./MobileHeader.scss";
import Search from "../Search/Search";
import MobileTopBar from "../MobileTopBar/MobileTopBar";

const MobileHeader = () => {
  return (
    <div className="mobile-header">
      <div className="wrap">
        <MobileTopBar />
        <Search />
      </div>
    </div>
  );
};

export default React.memo(MobileHeader);
