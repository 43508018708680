import { useEffect, useRef } from "react";

export const useClickOutside = (onAction: () => void) => {
  const divRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (divRef.current && !divRef.current.contains(target)) {
      onAction();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return { divRef };
};
