import { CustomButton } from "@Components/CustomButton";
import React, { FC } from "react";
import "./FormButtons.scss";

interface PT {
  nextTitle: string;
  prevTitle: string;
  disabled?: boolean;
  onNext?: () => void;
  onBack?: () => void;
}

export const FormButtons: FC<PT> = (props: PT) => {
  return (
    <div className="form-buttons">
      <CustomButton
        title={props.prevTitle}
        onClick={() => {
          props.onBack?.();
        }}
      />
      <CustomButton
        title={props.nextTitle}
        onClick={() => {
          props.onNext?.();
        }}
        disabled={props.disabled}
      />
    </div>
  );
};
