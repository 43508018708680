import { CustomImage } from "@Components/CustomImage";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ProgressItem.scss";

interface Props {
  isActive: boolean;
  isCurrent: boolean;
  index: number;
  total: number;
  name: string;
}

const ProgressItem: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={`progress-item ${
        props.isCurrent
          ? "progress-active progress-current"
          : props.isActive
          ? "progress-active"
          : "progress-inactive"
      }`}
    >
      <div
        className={`progress-item-dash ${
          props.index === 0
            ? "progress-item-first-dash"
            : "progress-item-normal-dash"
        }`}
      ></div>
      <div className="progress-item-main">
        <div className="progress-ball">
          {props.isActive ? (
            <CustomImage className="progress-checked" />
          ) : (
            props.index + 1
          )}
        </div>
        <div className="progress-title">{t(props.name)}</div>
      </div>
      {props.total === props.index + 1 && (
        <div className="progress-item-dash progress-item-first-dash"></div>
      )}
    </div>
  );
};

export default React.memo(ProgressItem);
