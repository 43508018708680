import {
  PersonalizeProps,
  personalizeActions,
  personalizeSelector,
} from "@Redux";
import { CharactorProperty } from "@Services";
import { GenderEnum, MainRouteEnum, ParamEnum, PersonalizePhase } from "@Types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

type ButtonTitleState = {
  prevTitle: "Home" | "Prevous step";
  nextTitle: "Next Step" | "Preview";
};

export const useRenderHelper = (charPropery: CharactorProperty) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const personalizeState = useSelector(personalizeSelector);
  const [activeNext, setActiveNext] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>();
  const [queryString, setQueryString] = useState<string>();
  const [buttonTitle, setButtonTitle] = useState<ButtonTitleState>();
  const [personalizePhase, setPersonalizePhase] = useState<PersonalizePhase>(
    PersonalizePhase.START
  );
  const currenCharacterId = personalizeState.currChar;
  const currentGender = personalizeState.properties.find(
    (item) => item.characterId == currenCharacterId
  )?.gender;

  const bookId = params[ParamEnum.book_id] as string;
  const productId = params[ParamEnum.product_id] as string;

  const onChangeValue = (value: string) => {
    if (value) {
      setActiveNext(true);
    } else {
      setActiveNext(false);
    }

    setInputValue(value);
    dispatch(
      personalizeActions.updateProperty({
        characterId: personalizeState.currChar as string,
        [charPropery.field]: value,
      })
    );
  };

  const onPrevStep = () => {
    const currCharIndex = personalizeState.currCharIndex || 0;
    const currStep = personalizeState.currStep || 0;
    switch (personalizePhase) {
      case PersonalizePhase.START:
        navigate(`${MainRouteEnum.home}`);
        break;

      case PersonalizePhase.PROCESSING:
        if (currStep == 1) {
          const prevCharIndex = currCharIndex - 1;
          const prevChar = personalizeState.charactersInfo.find(
            (char) => char.index == prevCharIndex
          );
          dispatch(
            personalizeActions.setCurrentChar({
              currStep: prevChar?.totalStep,
              currCharIndex: currCharIndex - 1,
              currChar: prevChar?.character,
              totalStep: prevChar?.totalStep,
            })
          );
        } else {
          dispatch(
            personalizeActions.setCurrentChar({ currStep: currStep - 1 })
          );
        }
        break;

      case PersonalizePhase.COMPLETE:
        dispatch(personalizeActions.setCurrentChar({ currStep: currStep - 1 }));
        break;

      default:
        break;
    }
  };

  const onNextStep = () => {
    const currCharIndex = personalizeState.currCharIndex || 0;
    const currStep = personalizeState.currStep || 0;
    const totalStep = personalizeState.totalStep || 0;

    switch (personalizePhase) {
      case PersonalizePhase.START:
        dispatch(personalizeActions.setCurrentChar({ currStep: currStep + 1 }));
        break;

      case PersonalizePhase.PROCESSING:
        if (currStep < totalStep) {
          dispatch(
            personalizeActions.setCurrentChar({ currStep: currStep + 1 })
          );
        } else {
          const nextCharIndex = currCharIndex + 1;
          const nextChar = personalizeState.charactersInfo.find(
            (char) => char.index == nextCharIndex
          );
          dispatch(
            personalizeActions.setCurrentChar({
              currStep: 1,
              currCharIndex: currCharIndex + 1,
              currChar: nextChar?.character,
              totalStep: nextChar?.totalStep,
            })
          );
        }
        break;

      case PersonalizePhase.COMPLETE:
        navigate(`/${MainRouteEnum["preview-book"]}/${productId}/${bookId}`, {
          state: personalizeState.properties,
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const currentPersonalizeState =
      personalizeState.properties.find(
        (item) => item.characterId == personalizeState.currChar
      ) || {};
    const { name, characterId, gender, ...previewProps } =
      currentPersonalizeState;

    const queryString = renderQueryString({
      character: characterId,
      ...previewProps,
    });
    setQueryString(queryString);

    const btnTitle = renButtonTitle(personalizePhase);
    setButtonTitle(btnTitle);

    const phase = phaseTracking(personalizeState);
    setPersonalizePhase(phase);
  }, [personalizeState, personalizePhase]);

  return {
    onChangeValue,
    onPrevStep,
    onNextStep,
    activeNext,
    queryString,
    bookId,
    buttonTitle,
    inputValue,
    currentGender,
  };
};

export const renderQueryString = (data: { [key: string]: string }) => {
  const queryString = Object.entries(data)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return queryString;
};

const phaseTracking = (
  personalizeState: PersonalizeProps
): PersonalizePhase => {
  const currCharIndex = personalizeState.currCharIndex || 0;
  const currStep = personalizeState.currStep || 0;
  const totalStep = personalizeState.totalStep || 0;
  const totalCharCount = personalizeState.totalCharCount || 0;

  const isStartPhase = currCharIndex < totalCharCount && currStep == 1;
  if (isStartPhase) return PersonalizePhase.START;

  const isComplete = currStep == totalStep && currCharIndex == totalCharCount;
  if (isComplete) return PersonalizePhase.COMPLETE;

  return PersonalizePhase.PROCESSING;
};

const renButtonTitle = (phase: PersonalizePhase): ButtonTitleState => {
  switch (phase) {
    case PersonalizePhase.START:
      return {
        prevTitle: "Home",
        nextTitle: "Next Step",
      };
    case PersonalizePhase.PROCESSING:
      return {
        prevTitle: "Prevous step",
        nextTitle: "Next Step",
      };
    case PersonalizePhase.COMPLETE:
      return {
        prevTitle: "Prevous step",
        nextTitle: "Preview",
      };
  }
};
