import { CustomImage } from "@Components/CustomImage";
import { CustomInput } from "@Components/CustomInput";
import { useDebounce, useGetProducts } from "@Hooks";
import { GetProductsQuery } from "@generated";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Search.scss";
import { SearchDropdown } from "./components/SearchDropdown";
import { ProductsResponse, SaleorAppApis } from "@Services";

const Search = () => {
  const [inputValue, setInputValue] = useState<string>();
  const [products, setProducts] = useState<ProductsResponse>();
  const { t } = useTranslation();
  const debouncedSearchTerm = useDebounce(inputValue, 500);

  const onSuccess = (data: ProductsResponse) => {
    setProducts(data);
  };
  useEffect(() => {
    SaleorAppApis.getProducts(
      { search: debouncedSearchTerm || "default-search" },
      onSuccess
    );
  }, [debouncedSearchTerm]);

  return (
    <div className="search-wrap">
      <CustomInput
        placeholder={t("Search our store")}
        className="search-input"
        onChange={setInputValue}
        suffix={<CustomImage className="search-icon" />}
      />
      {products && (
        <SearchDropdown
          searchTerm={debouncedSearchTerm}
          {...(products.data?.data as GetProductsQuery)}
        />
      )}
    </div>
  );
};

export default React.memo(Search);
