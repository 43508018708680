import { SALE_CHANNEL } from "@Graphql";
import { useQuery } from "@apollo/client";
import {
  GetProductDetailDocument,
  GetProductDetailQuery,
  GetProductDetailQueryVariables,
  ProductImage,
} from "@generated";
import React from "react";
import "react-rater/lib/react-rater.css";
import { useLocation } from "react-router-dom";
import { DemoQA } from "../DemoQA/DemoQA";
import { DetailDescBody } from "../DetailDescBody/DetailDescBody";
import { DetailDescFooter } from "../DetailDescFooter/DetailDescFooter";
import { DetailDescHeader } from "../DetailDescHeader/DetailDescHeader";
import { ImgCarousel } from "../ImgCarousel/ImgCarousel";
import { MiniImg } from "../MiniImg/MiniImg";
import "./MainDetail.scss";

export const MainDetail = () => {
  const location = useLocation();
  const productId = location.pathname.split("/")[2];

  const variables: GetProductDetailQueryVariables = {
    id: productId,
    channel: SALE_CHANNEL,
  };

  const { loading, error, data } = useQuery(GetProductDetailDocument, {
    variables,
  });
  if (loading) return <></>;

  const previewImages = (data?.product?.media as ProductImage[]) || [];

  return (
    <div className="page-body">
      <div className="main-detail">
        <div className="wrap">
          <MiniImg previewImages={previewImages} />
          <ImgCarousel previewImages={previewImages} />
          {data && <DetailDescHeader data={data as GetProductDetailQuery} />}

          <div className="stick-detail">
            {data && <DetailDescBody data={data as GetProductDetailQuery} />}
            <DemoQA />
            {data && <DetailDescFooter data={data as GetProductDetailQuery} />}
          </div>
        </div>
      </div>
    </div>
  );
};
