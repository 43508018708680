import React from "react";
import "./DropdownItem.scss";
import { SelectItemType } from "@Types";

interface PT {
  data: SelectItemType;
  handleClose: (e: string | null) => void;
  selected: string | null;
}

const DropdownItem: React.FC<PT> = ({ data, handleClose, selected }) => {
  const onClick = () => {
    handleClose(data.value);
  };
  return (
    <li
      onClick={onClick}
      className={`dropdown-item ${
        selected === data.value
          ? "dropdown-item-active"
          : "dropdown-item-inactive"
      }`}
    >
      <div className="dropdown-item-wrap">
        {data.label}
        <div className="dropdown-item-icon "></div>
      </div>
    </li>
  );
};

export default React.memo(DropdownItem);
