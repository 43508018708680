import React from "react";
import "./MobileTopBar.scss";
import IconFeature from "../IconFeature/IconFeature";
import MainLogo from "../MainLogo/MainLogo";
import HiddenMenuButton from "../HiddenMenuButton/HiddenMenuButton";

const MobileTopBar = () => {
  return (
    <div className="mobile-top-bar">
      <HiddenMenuButton />
      <MainLogo />
      <IconFeature />
    </div>
  );
};

export default React.memo(MobileTopBar);
