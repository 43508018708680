import React from "react";

export const ArrowRightIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.332683 6.16668H10.4743L5.81602 1.50834L6.99935 0.333344L13.666 7.00001L6.99935 13.6667L5.82435 12.4917L10.4743 7.83334H0.332683V6.16668Z"
        fill="#2A2F36"
      />
    </svg>
  );
};
