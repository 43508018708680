import { CustomRadio, FormButtons, RetriveImage } from "@Components";
import { useRenderHelper } from "@Hooks";
import { CharactorProperty, PropertyOptions } from "@Services";
import { SelectItemType } from "@Types";
import React from "react";
import "./SelectColorForm.scss";

interface Props extends CharactorProperty {}

export const SelectColorForm: React.FC<Props> = (props: Props) => {
  const {
    bookId,
    queryString,
    activeNext,
    buttonTitle,
    onChangeValue,
    onNextStep,
    onPrevStep,
  } = useRenderHelper(props);

  const renderRadioOptions = (options: PropertyOptions[]) => {
    const convert = options.map((option) => ({
      value: option.key,
      label: (
        <div
          className="select-color-form"
          style={{ backgroundColor: `${option.value}` }}
        ></div>
      ),
    }));

    return convert;
  };

  return (
    <>
      {props?.preview && (
        <div className="preview-character">
          <RetriveImage path={`${bookId}-preview/${queryString}.png`} />
        </div>
      )}
      <div className="custom-form-title">{props.title}</div>

      <div className="custom-form-desc">Please select your {props.title}?</div>
      <CustomRadio
        options={
          renderRadioOptions(
            props.options as PropertyOptions[]
          ) as SelectItemType[]
        }
        onChange={onChangeValue}
        isImg
      />
      <FormButtons
        onNext={onNextStep}
        onBack={onPrevStep}
        prevTitle={buttonTitle?.prevTitle as string}
        nextTitle={buttonTitle?.nextTitle as string}
        disabled={!activeNext}
      />
    </>
  );
};
