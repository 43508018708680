import React from "react";
import "./Footer.scss";
import BottomFIlter from "./components/BottomFIlter/BottomFIlter";
import { BottomMenu } from "./components/BottomMenu/BottomMenu";
import { Copyright } from "./components/Copyright/Copyright";
import Help from "./components/Help/Help";
import { Subcribe } from "./components/Subcribe/Subcribe";

interface Props {
  activeSubcribe: boolean;
}
export const Footer: React.FC<Props> = (prop: Props) => {
  return (
    <div className="footer">
      <div className="wrap">
        {prop.activeSubcribe && <Subcribe />}
        <div className="footer-ref">
          <BottomMenu />
          <Help />
          <BottomFIlter />
        </div>
        <Copyright />
      </div>
    </div>
  );
};
