import React from "react";
import "./FullHeader.scss";
import TopBar from "../TopBar/TopBar";
import Menu from "../Menu/Menu";

const FullHeader = () => {
  return (
    <div className="full-header">
      <div className="wrap">
        <TopBar />
        <Menu />
      </div>
    </div>
  );
};

export default React.memo(FullHeader);
