import React from "react";
import "./MainThumbnailRight.scss";
import { CustomImage } from "@Components/CustomImage";

const MainThumbnailRight = () => {
  return (
    <div className="main-thumbnail-right">
      <CustomImage className="main-thumbnail-right-red-half" />
      <CustomImage className="main-thumbnail-right-boy" />
      <CustomImage className="main-thumbnail-right-house" />
      <CustomImage className="main-thumbnail-right-rocket" />
      <CustomImage className="main-thumbnail-right-galaxy" />
    </div>
  );
};

export default React.memo(MainThumbnailRight);
