import { CustomButton } from "@Components/CustomButton";
import { CustomImage } from "@Components/CustomImage";
import React from "react";
import "./MainThumbnailLeft.scss";

const MainThumbnailLeft = () => {
  const onClick = () => {
    console.log("Shop bestsellers");
  };

  return (
    <div className="main-thumbnail-left">
      <h1 className="main-thumbnail-left-first-header">
        Teaching Reading is Easy with
      </h1>
      <h2 className="main-thumbnail-left-second-header">SuperBooks</h2>
      <p className="main-thumbnail-left-paragragh">
        It is with immense pleasure and pride that I extend my warmest
        congratulations to you on being honored with the award for the Best
        Homeschool Curriculum
      </p>
      <CustomButton
        title="Shop bestsellers"
        onClick={onClick}
        className="main-thumbnail-left-btn"
      />
      <CustomImage className="main-thumbnail-left-flowers" />
    </div>
  );
};

export default React.memo(MainThumbnailLeft);
