import React from "react";
import "./BottomMenu.scss";
import { CustomImage } from "@Components/CustomImage";
import MainLogo from "../../../Header/components/MainLogo/MainLogo";

const bottomMenuArray = [
  "Become Playhouse",
  "Blog",
  "Our Story",
  "Refer a Friend",
  "Offers",
];

const bottomRefArray = ["fb", "pin", "x", "ins", "tt"];

export const BottomMenu = () => {
  return (
    <div className="bottom-menu">
      <div>
        <div className="bottom-logo">
          <MainLogo />
        </div>
        {bottomMenuArray.map((a, i) => (
          <p key={i} className="bottom-menu-item">
            {a}
          </p>
        ))}
      </div>

      <div className="bottom-menu-refs">
        {bottomRefArray.map((a, i) => (
          <div className="bottom-menu-ref" key={i}>
            <CustomImage className={`bottom-menu-ref-img-${a}`} />
          </div>
        ))}
      </div>
    </div>
  );
};
