import { SALE_CHANNEL } from "@Graphql";
import { useQuery } from "@apollo/client";
import {
  GetProductsDocument,
  GetProductsQueryVariables,
  ProductNodeFragment,
} from "@generated";
import BestSellerItem from "./BestSellerItem/BestSellerItem";
import "./BestSellers.scss";
import { useGetProducts } from "@Hooks";

export const BestSellers = () => {
  const { products } = useGetProducts({});
  const productNodes = products?.data?.data.products?.edges || [];

  // render product cart item
  const productRender = (product: ProductNodeFragment) => {
    const thumbnail = product.node.media?.[0].url as string;
    const description =
      JSON.parse(product.node.description)?.blocks[0]?.data?.text || "";

    return (
      <BestSellerItem
        key={product.node.id}
        productName={product.node.name}
        description={description}
        thumbnail={thumbnail}
        productId={product.node.id as string}
      />
    );
  };

  return (
    <div className="best-sellers">
      <div className="wrap">
        <h2 className="best-sellers-title">Our bestsellers</h2>
        <div className="best-sellers-array">
          {productNodes.map((product, index) => productRender(product))}
        </div>
      </div>
    </div>
  );
};
