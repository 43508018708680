import { ArrowRightIcon } from "@Assets/icons";
import { ParamEnum } from "@Types";
import { useMutation } from "@apollo/client";
import { CheckoutComplete, CheckoutCompleteDocument } from "@generated";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Order.scss";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

export const Order: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["checkoutToken"]);

  const [order, setOrder] = useState<CheckoutComplete>();

  const checkoutToken = params[ParamEnum.checkout_token];

  const [checkoutComplete, { loading: CheckoutCompleteLoading }] = useMutation(
    CheckoutCompleteDocument,
    {
      onCompleted: (data) => {
        setOrder(data.checkoutComplete as CheckoutComplete);
        removeCookie("checkoutToken");
        toast.success("Order success!");
      },
      onError: (err) => {
        toast.success("Order error!");
      },
    }
  );

  const onBack = () => {
    navigate("/home");
  };

  useEffect(() => {
    checkoutComplete({ variables: { token: checkoutToken } });
  }, [checkoutToken]);

  return (
    <div className="order-page-wrap">
      <p className="order-title">Order Detail</p>

      <div className="order-wrap">
        <p>
          <span style={{ fontWeight: "bold" }}>Order Code:</span>{" "}
          {order?.order?.id}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
          {order?.order?.userEmail}
        </p>
        <div>
          <p style={{ fontWeight: "bold" }}>Billing Address:</p>
          <p>
            <span>First Name: </span> {order?.order?.billingAddress?.firstName}
          </p>
          <p>
            <span>Lirst Name: </span> {order?.order?.billingAddress?.lastName}
          </p>
          <p>
            <span>Country: </span>{" "}
            {order?.order?.billingAddress?.country.country}
          </p>
          <p>
            <span>City: </span> {order?.order?.billingAddress?.city}
          </p>
          <p>
            <span>Address: </span>{" "}
            {order?.order?.billingAddress?.streetAddress1}
          </p>
          <p style={{ fontWeight: "bold" }}>
            Status:{" "}
            {order?.order?.isPaid ? (
              <span style={{ color: "green" }}> PAID </span>
            ) : (
              <span style={{ color: "red" }}> PAID </span>
            )}
          </p>
        </div>
      </div>
      <p onClick={onBack} className="form-wrap-back">
        <span>Home page</span>
        <ArrowRightIcon />
      </p>
    </div>
  );
};
