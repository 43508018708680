import React from "react";

export const SubtractIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" rx="5" fill="#E5E5E5" />
      <path
        d="M19.834 13.1667H8.16732C7.9463 13.1667 7.73434 13.2545 7.57806 13.4107C7.42178 13.567 7.33398 13.779 7.33398 14C7.33398 14.221 7.42178 14.433 7.57806 14.5892C7.73434 14.7455 7.9463 14.8333 8.16732 14.8333H19.834C20.055 14.8333 20.267 14.7455 20.4232 14.5892C20.5795 14.433 20.6673 14.221 20.6673 14C20.6673 13.779 20.5795 13.567 20.4232 13.4107C20.267 13.2545 20.055 13.1667 19.834 13.1667Z"
        fill="#444444"
      />
    </svg>
  );
};
