import React from "react";
import "./HiddenMenuList.scss";
import {
  GetCategoriesDocument,
  GetCategoriesQueryVariables,
} from "src/generated/graphql";
import { useQuery } from "@apollo/client";

const HiddenMenuList = () => {
  const variables: GetCategoriesQueryVariables = {
    first: 100,
  };

  const { loading, error, data } = useQuery(GetCategoriesDocument, {
    variables,
  });

  if (loading) return <></>;

  const caregoriesNode = data?.categories?.edges || [];

  return (
    <div className="hidden-menu-list">
      {caregoriesNode.map((category) => (
        <div className="hidden-menu-item" key={category.node.id}>
          {category.node.name}
        </div>
      ))}
    </div>
  );
};

export default React.memo(HiddenMenuList);
