import React from "react";
import "./GreenLineItem.scss";
import { CustomImage } from "@Components/CustomImage";

interface PT {
  title: string;
  desc: string;
  img: string;
}

export const GreenLineItem: React.FC<PT> = ({ title, desc, img }) => {
  return (
    <div className="green-line-item">
      <CustomImage className={`green-line-item-img-${img}`} />
      <div>
        <h5 className="green-line-title">{title}</h5>
        <p className="green-line-desc">{desc}</p>
      </div>
    </div>
  );
};
