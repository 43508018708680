import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CartState {
  checkoutId: string;
  checkoutToken: string;
  itemCount: number;
}

const initialState: CartState = {
  checkoutId: "",
  checkoutToken: "",
  itemCount: 0,
};

export const cartSlice = createSlice({
  name: "carts",
  initialState,
  reducers: {
    reset: () => initialState,

    setCart: (state, action: PayloadAction<CartState>) => {
      state.itemCount = action.payload.itemCount;
      state.checkoutId = action.payload.checkoutId;
      state.checkoutToken = action.payload.checkoutToken;
    },

    changeCount: (state, action: PayloadAction<number>) => {
      state.itemCount += action.payload;
    },
  },
});

export const cartReducer = cartSlice.reducer;
export const cartActions = cartSlice.actions;
