import React, { ReactNode } from "react";
import "./CustomButton.scss";

interface PT {
  title: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
}

export const CustomButton: React.FC<PT> = ({
  title,
  onClick,
  disabled,
  hidden,
  className,
}) => {
  return (
    <div
      className={`button-container ${
        disabled ? "button-disabled" : "button-active"
      }${className ? " " + className : ""}`}
    >
      <button
        hidden={hidden}
        disabled={disabled}
        className="customized-button"
        onClick={() => {
          if (!disabled) onClick?.();
        }}
      >
        {title}
      </button>
    </div>
  );
};
