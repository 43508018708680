import { CustomImage } from "@Components/CustomImage";
import { cartActions, cartSelector } from "@Redux";
import { useQuery } from "@apollo/client";
import {
  GetCheckoutDetailDocument,
  GetCheckoutDetailQueryVariables,
} from "@generated";
import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./IconFeature.scss";

const IconFeature = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const count = useSelector(cartSelector).itemCount;

  const [cookies] = useCookies(["checkoutToken"]);
  const checkoutToken = cookies?.checkoutToken;

  const variables: GetCheckoutDetailQueryVariables = {
    token: checkoutToken,
  };

  checkoutToken &&
    useQuery(GetCheckoutDetailDocument, {
      variables,
      onCompleted(data) {
        dispatch(
          cartActions.setCart({
            checkoutId: data.checkout?.id as string,
            itemCount: data.checkout?.lines.length as number,
            checkoutToken: data.checkout?.token,
          })
        );
      },
    });

  const onOpenCart = () => {
    if (count > 0) {
      navigate("/cart");
    }
  };
  return (
    <div className="icon-feature">
      <CustomImage className="icon-feature-heart" />
      <div onClick={onOpenCart} className="icon-feature-bag-wrap">
        <CustomImage className="icon-feature-bag" />
        {count > 0 && <p>{count}</p>}
      </div>
    </div>
  );
};

export default React.memo(IconFeature);
