import React from "react";
import HTMLFlipBook from "react-pageflip";
import "./Flippingbook.scss";
import { CoverPage, Page } from "./PlippingPage/FlippingPage";
import { PreviewBookType } from "@Services";

interface Props {
  previewData: PreviewBookType[];
}

export const FlippingBook: React.FC<Props> = ({ previewData }) => {
  return (
    <div className="book-wrap">
      <HTMLFlipBook
        width={650}
        height={650}
        className={""}
        style={{}}
        startPage={0}
        size={"stretch"}
        minWidth={0}
        maxWidth={0}
        minHeight={0}
        maxHeight={0}
        drawShadow={true}
        flippingTime={700}
        usePortrait={false}
        startZIndex={0}
        autoSize={true}
        maxShadowOpacity={0}
        showCover={false}
        mobileScrollSupport={false}
        clickEventForward={true}
        useMouseEvents={true}
        swipeDistance={0}
        showPageCorners={true}
        disableFlipByClick={false}
      >
        <CoverPage />
        {previewData.map((item) => (
          <Page
            key={item.page}
            page={item.page}
            url={item.url}
            editDedications={item?.editDedications}
          />
        ))}
        <CoverPage />
      </HTMLFlipBook>
    </div>
  );
};
