import React from "react";
import "./GreenLine.scss";
import { CustomImage } from "@Components/CustomImage";
import { GreenLineItem } from "./GreenLineItem/GreenLineItem";

const greenLineArray = [
  {
    title: "Ship worldwide",
    desc: "Printed and shipped within 48 hours",
    img: "air",
  },
  {
    title: "Choose their cover",
    desc: "Pick a bespoke design they’ll love",
    img: "wallet",
  },
  {
    title: "Add gift wrap",
    desc: "Pick a bespoke design they’ll love",
    img: "map",
  },
  {
    title: "Write a dedication",
    desc: "Add your own words to the first page",
    img: "table",
  },
];

export const GreenLine = () => {
  return (
    <div className="green-line">
      <CustomImage className="green-line-img">
        <div className="wrap">
          <div className="green-line-main">
            {greenLineArray.map((a, i) => (
              <GreenLineItem key={i} {...a} />
            ))}
          </div>
        </div>
      </CustomImage>
    </div>
  );
};
