import React from "react";

export const StarIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.63852 5.31998L12.8327 5.68165L9.65352 8.44081L10.6043 12.5416L6.99935 10.3658L3.39435 12.5416L4.35102 8.44081L1.16602 5.68165L5.36018 5.32581L6.99935 1.45831L8.63852 5.31998ZM4.80602 10.5991L6.99935 9.27498L9.19852 10.605L8.61518 8.10831L10.5518 6.42831L7.99685 6.20665L6.99935 3.84998L6.00768 6.20081L3.45268 6.42248L5.38935 8.10248L4.80602 10.5991Z"
        fill="#FA7436"
      />
    </svg>
  );
};
