import { useEffect } from "react";

export const useQuickClose = (isShowing: boolean, onCancel: () => void) => {
  useEffect(() => {
    const onKeyDown = (e: any) => {
      e.keyCode === 27 && onCancel && onCancel();
    };

    isShowing
      ? addEventListener("keydown", onKeyDown)
      : removeEventListener("keydown", onKeyDown);

    return () => {
      removeEventListener("keydown", onKeyDown);
    };
  }, [isShowing]);
};
