import { ProductCard } from "@Components";
import { Footer, Header } from "@Containers";
import { useGetProducts, useQuery } from "@Hooks";
import { ProductNodeFragment } from "@generated";
import React from "react";
import "./Products.scss";

export const Products: React.FC = () => {
  const query = useQuery();
  const { products } = useGetProducts(query);

  const productNodes = products?.data?.data.products?.edges || [];

  // render product cart item
  const renderProduct = (product: ProductNodeFragment) => {
    const thumbnail = product.node.media?.[0].url as string;
    const description =
      JSON.parse(product.node.description)?.blocks[0]?.data?.text || "";

    return (
      <ProductCard
        key={product.node.id}
        productName={product.node.name}
        description={description}
        thumbnail={thumbnail}
        productId={product.node.id as string}
      />
    );
  };
  return (
    <>
      <Header />
      <div className="page-body">
        <div className="wrap">
          <div className="products-wrap">
            {productNodes.map((item) => renderProduct(item))}
          </div>
        </div>
      </div>

      <Footer activeSubcribe={false} />
    </>
  );
};
