import React from "react";
import "./DemoQA.scss";
import { CustomImage } from "@Components/CustomImage";

const DemoQAArray = [
  "How is the book personalized?",
  "What’s the story?",
  "Size & quality",
];

export const DemoQA = () => {
  return (
    <div className="demo-qa">
      {DemoQAArray.map((a, i) => (
        <div className="bottom-filter" key={i}>
          <p className="bottom-filter-desc">{a}</p>
          <CustomImage className="bottom-filter-img" />
        </div>
      ))}
    </div>
  );
};
