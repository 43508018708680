import React from "react";

export const BookIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33398 11.375C2.33398 10.9882 2.48763 10.6173 2.76112 10.3438C3.03461 10.0703 3.40554 9.91669 3.79232 9.91669H11.6673"
        stroke="#FA7436"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.79232 1.16669H11.6673V12.8334H3.79232C3.40554 12.8334 3.03461 12.6797 2.76112 12.4062C2.48763 12.1327 2.33398 11.7618 2.33398 11.375V2.62502C2.33398 2.23825 2.48763 1.86731 2.76112 1.59382C3.03461 1.32033 3.40554 1.16669 3.79232 1.16669V1.16669Z"
        stroke="#FA7436"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
