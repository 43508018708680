import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GetCategoriesDocument, GetCategoriesQueryVariables } from "@generated";
import "./Menu.scss";
import MenuItem from "./MenuItem/MenuItem";
import { useGetCollections } from "@Hooks";
import { useNavigate } from "react-router-dom";

const Menu: React.FC = () => {
  const navigate = useNavigate();
  const [activeCollection, setActiveCollection] = useState<string>("");
  const { collections } = useGetCollections();

  const { loading, error, data } = useQuery(GetCategoriesDocument, {
    variables: { first: 100 },
  });
  const caregoriesNode = data?.categories?.edges || [];

  const onShowCollection = (categoryId: string) => {
    setActiveCollection(categoryId);
  };

  const onHideCollections = () => {
    setActiveCollection("");
  };

  const renderCollections = (categoryId: string) => {
    const collectionNodes = collections?.data?.data.collections?.edges.filter(
      (item) => item.node.metafield == categoryId
    );
    const onClick = (collectionId: string) => {
      navigate(`/products?collection=${collectionId}`);
    };

    return (
      <div className="menu-collection" onMouseLeave={onHideCollections}>
        {collectionNodes &&
          collectionNodes?.length > 0 &&
          collectionNodes?.map((item) => (
            <p
              onClick={() => onClick(item.node.id)}
              className="menu-collection-item"
            >
              {item.node.name}
            </p>
          ))}
      </div>
    );
  };

  return (
    <div className="menu">
      {caregoriesNode.map((category) => (
        <>
          <MenuItem
            key={category.node.id}
            title={category.node.name}
            categoryId={category.node.id}
            onClick={onShowCollection}
          />
          {activeCollection == category.node.id &&
            renderCollections(category.node.id)}
        </>
      ))}
    </div>
  );
};

export default React.memo(Menu);
