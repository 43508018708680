import React, { ReactNode } from "react";
import "./CustomImage.scss";

interface PT {
  className: string;
  children?: ReactNode;
}

export const CustomImage: React.FC<PT> = ({ className, children }) => {
  return <div className={`custom-image ${className}`}>{children}</div>;
};
