import { FormButtons, RetriveImage } from "@Components";
import { CustomRadio } from "@Components/CustomRadio";
import { useRenderHelper } from "@Hooks";
import { CharactorProperty } from "@Services";
import React from "react";
import "./SellectForm.scss";

interface Props extends CharactorProperty {}

export const SellectForm: React.FC<Props> = (props: Props) => {
  const {
    activeNext,
    buttonTitle,
    bookId,
    queryString,
    onChangeValue,
    onNextStep,
    onPrevStep,
  } = useRenderHelper(props);

  const options = props.options || [];

  const renderOption = options.map((item) => ({
    label: item.label,
    value: item.value,
  }));

  return (
    <>
      {props?.preview && (
        <div className="preview-character">
          <RetriveImage path={`${bookId}-preview/${queryString}.png`} />
        </div>
      )}
      <div className="custom-form-title">{props.title}</div>
      <div className="custom-form-desc">Please select your {props.title}?</div>
      <CustomRadio options={renderOption} onChange={onChangeValue} />
      <FormButtons
        onNext={onNextStep}
        onBack={onPrevStep}
        prevTitle={buttonTitle?.prevTitle as string}
        nextTitle={buttonTitle?.nextTitle as string}
        disabled={!activeNext}
      />
    </>
  );
};
