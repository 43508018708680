import { CustomImage } from "@Components";
import React from "react";
import "./LanguageItem.scss";

interface PT {
  value: string;
  label: string;
}

const LanguageItem: React.FC<PT> = ({ value, label }) => {
  return (
    <div className="language-item">
      <CustomImage className={`language-item-flag language-item-${value}`} />
      {label}
    </div>
  );
};

export default React.memo(LanguageItem);
