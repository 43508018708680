import { CustomImage } from "@Components/CustomImage";
import React from "react";
import "./BottomFIlter.scss";

const bottomFIlterArray = [
  "Shop by Recipient and Age",
  "Shop by Occasion",
  "Shop by Book Series",
  "Shop by Countries",
  "Dedication Insporation",
];

const bottomRefArray = ["vs", "mc", "pp", "kl", "am", "ap"];

const BottomFIlter = () => {
  return (
    <div className="bottom-filter-wrap">
      <div>
        {bottomFIlterArray.map((a, i) => (
          <div className="bottom-filter" key={i}>
            <p className="bottom-filter-desc">{a}</p>
            <CustomImage className="bottom-filter-img" />
          </div>
        ))}
      </div>
      <div className="bottom-filter-refs">
        {bottomRefArray.map((a, i) => (
          <div className="bottom-filter-ref" key={i}>
            <CustomImage className={`bottom-filter-ref-${a}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(BottomFIlter);
