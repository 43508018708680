import { Detail, Home } from "@Containers";
import { Cart } from "@Containers/Carts";
import { Checkout } from "@Containers/Checkout";
import { Order } from "@Containers/Order";
import { Personalized } from "@Containers/Personalized";
import { PreviewBook } from "@Containers/PreviewBook";
import { Products } from "@Containers/Products";

import { MainRoutesType, MainRouteEnum, ParamEnum } from "@Types";
import { SaleorApp } from "../Containers/SaleorApp/SaleorApp";

export const MainRoutes: MainRoutesType[] = [
  {
    path: MainRouteEnum.home,
    element: <Home />,
  },
  {
    path: `/${MainRouteEnum.detail}/:${ParamEnum.product_id}`,
    element: <Detail />,
  },
  {
    path: `/${MainRouteEnum.personalized}/:${ParamEnum.product_id}/:${ParamEnum.book_id}`,
    element: <Personalized />,
  },
  {
    path: `/${MainRouteEnum["preview-book"]}/:${ParamEnum.product_id}/:${ParamEnum.book_id}`,
    element: <PreviewBook />,
  },
  {
    path: `/${MainRouteEnum["checkout"]}/:${ParamEnum.checkout_token}`,
    element: <Checkout />,
  },
  {
    path: `/${MainRouteEnum["cart"]}`,
    element: <Cart />,
  },
  {
    path: `/${MainRouteEnum["products"]}`,
    element: <Products />,
  },
  {
    path: `/${MainRouteEnum["order"]}/:${ParamEnum.checkout_token}`,
    element: <Order />,
  },
  {
    path: `/${MainRouteEnum["saleor-app"]}`,
    element: <SaleorApp />,
  },

  // Wrong nav
  {
    path: "*",
    element: <Home />,
  },
];
