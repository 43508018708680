import React from "react";
import { UseFormRegister } from "react-hook-form";
import "./InputForm.scss";

interface Props {
  lable: string;
  name: string;
  placeHolder?: string;
  required?: boolean;
  helperText?: string;
  register: UseFormRegister<any>;
}

export const InputForm: React.FC<Props> = ({
  name,
  lable,
  required,
  helperText,
  placeHolder,
  register,
}) => {
  return (
    <div className="input-form-warp">
      <label className="input-form-lable">
        {lable}
        {!!required && <span>*</span>}
      </label>

      <input
        className="input-form-item"
        placeholder={placeHolder}
        {...register(name, {
          required: {
            value: required as boolean,
            message: `${lable} is require!`,
          },
        })}
      />
      {helperText && (
        <span className="input-form-helperText">{helperText}</span>
      )}
    </div>
  );
};
