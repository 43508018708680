import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SliderState } from "./slider.type";

const initialState: SliderState = {
  slickRef: null,
  selectedDemoImg: 0,
};

export const sliderSlice = createSlice({
  name: "slider",
  initialState,
  reducers: {
    reset: () => initialState,

    setSlickRef: (state, action: PayloadAction<any>) => {
      state.slickRef = action.payload;
    },

    setSelectedDemoImg: (state, action: PayloadAction<number>) => {
      state.selectedDemoImg = action.payload;
    },
  },
});

export const sliderReducer = sliderSlice.reducer;
export const sliderActions = sliderSlice.actions;
