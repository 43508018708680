import { useGetBookProperties } from "@Hooks";
import { personalizeSelector } from "@Redux";
import React from "react";
import { useSelector } from "react-redux";
import "./Personalized.scss";
import { CharCount } from "./components/CharCount/CharCount";
import { CustomForm } from "./components/CustomForm/CustomForm";
import { Progress } from "./components/Progress/Progress";

export const Personalized: React.FC = () => {
  const { charactors } = useGetBookProperties();
  const personalizeState = useSelector(personalizeSelector);

  const currentChar = charactors?.find(
    (char) => char.index == personalizeState.currCharIndex
  );
  const formProps = currentChar?.properties.find(
    (item) => item.step == personalizeState.currStep
  );

  return (
    <>
      <CharCount />
      <div className="personalized">
        {currentChar && <Progress {...currentChar} />}
        {formProps && <CustomForm {...formProps} />}
      </div>
    </>
  );
};
