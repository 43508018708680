import uuid from "react-uuid";
import { Field } from "../../../../Types";
import { CountryCode } from "../../../../generated";
import { getCountryName } from "../../../../Utils/locale";

const mappingCountry = Object.entries(CountryCode).map(([key, value]) => ({
  key: value,
  value: getCountryName(value),
}));

export const ADDRESS_FIELDS: Field[] = [
  {
    id: uuid(),
    name: "email",
    type: "text",
    placeholder: "Email",
    required: true,
    label: "Email",
  },
  {
    id: uuid(),
    name: "firstName",
    type: "text",
    placeholder: "First Name",
    required: false,
    label: "First Name",
  },
  {
    id: uuid(),
    name: "lastName",
    type: "text",
    placeholder: "Last Name",
    required: false,
    label: "Last Name",
  },
  {
    id: uuid(),
    name: "streetAddress1",
    type: "text",
    placeholder: "Street Address",
    required: true,
    label: "Street Address",
  },
  {
    id: uuid(),
    name: "streetAddress2",
    type: "text",
    placeholder: "Street Address (additional)",
    required: false,
    label: "Street Address (additional)",
  },
  {
    id: uuid(),
    name: "city",
    type: "text",
    placeholder: "City",
    required: true,
    label: "City",
  },
  {
    id: uuid(),
    name: "postalCode",
    type: "text",
    placeholder: "Postal Code",
    required: true,
    label: "Postal Code",
  },
  {
    id: uuid(),
    name: "country",
    type: "select",
    placeholder: "Country",
    required: true,
    label: "Country",
    options: mappingCountry,
  },
];
