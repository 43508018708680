import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageItem from "../LanguageItem/LanguageItem";

const languagesRaw = [
  { value: "eng", label: "English" },
  { value: "vie", label: "Tiếng Việt" },
];

const useGetLanguage = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);

  const languages = languagesRaw.map((a) => {
    return { ...a, label: <LanguageItem {...a} /> };
  });

  const changeLanguage = (language: string | null) => {
    language && i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };

  useEffect(() => {
    setSelectedLanguage(languagesRaw[0].value);
  }, []);

  return { languages, selectedLanguage, changeLanguage };
};

export default useGetLanguage;
