import { CustomButton } from "@Components/CustomButton";
import { CustomImage } from "@Components/CustomImage";
import { CustomInput } from "@Components/CustomInput";
import React from "react";
import "./Subcribe.scss";

export const Subcribe: React.FC = () => {
  const onChange = (e: any) => {
    console.log(e);
  };

  const onClick = () => {
    console.log("Subscribe");
  };

  return (
    <div className="subcribe">
      <div className="subcribe-title">
        Subscribe and get exclusive deals & offer
      </div>

      <CustomInput
        onChange={onChange}
        className="subcribe-input"
        placeholder="Enter your mail"
        prefix={<CustomImage className="subcribe-mail" />}
        suffix={
          <CustomButton
            title="Subscribe"
            onClick={onClick}
            className="subcribe-btn"
          />
        }
      />
      <CustomButton
        title="Subscribe"
        onClick={onClick}
        className="subcribe-btn-outside"
      />
    </div>
  );
};
