import { CustomButton } from "@Components/CustomButton";
import { SALE_CHANNEL } from "@Graphql";
import { usePreviewProduct } from "@Hooks";
import { PreviewBookType } from "@Services";
import { useMutation, useQuery } from "@apollo/client";
import {
  CheckoutLineAddDocument,
  CreateCheckoutDocument,
  GetProductDetailDocument,
  GetProductDetailQueryVariables,
} from "@generated";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./PreviewBook.scss";
import { FlippingBook } from "./components/Flippingbook/FlippingBook";

export const PreviewBook = () => {
  const { productId, prevewData } = usePreviewProduct();

  const [cookies, setCookie] = useCookies(["checkoutToken"]);
  const navigate = useNavigate();

  const variables: GetProductDetailQueryVariables = {
    id: productId,
    channel: SALE_CHANNEL,
  };

  const {
    loading,
    error,
    data: productDetail,
  } = useQuery(GetProductDetailDocument, {
    variables,
  });

  const [checkoutCreate, { loading: createCheckoutLOading }] = useMutation(
    CreateCheckoutDocument,
    {
      onCompleted: (data) => {
        const now = new Date().getTime();
        const exripeDate = new Date(now + 30 * 24 * 60 * 60 * 1000);
        setCookie("checkoutToken", data.checkoutCreate?.checkout?.token, {
          expires: exripeDate,
        });
        toast.success("Create checkout success!");
        navigate("/cart");
        return;
      },
      onError: (err) => {
        toast.error("Create checkout error!");
      },
    }
  );

  const [checkoutLineAdd, { loading: addCheckoutLoading }] = useMutation(
    CheckoutLineAddDocument,
    {
      onCompleted: () => {
        toast.success("Add checkout item success!");
        navigate("/cart");
      },
      onError: (err) => {
        toast.success("Add checkout item Error!");
      },
    }
  );

  const onCheckout = () => {
    const checkoutToken = cookies?.checkoutToken;
    const payload = {
      lines: [
        {
          variantId: productDetail?.product?.defaultVariant?.id as string,
          quantity: 1,
        },
      ],
      token: checkoutToken,
    };

    if (!checkoutToken) {
      checkoutCreate({ variables: { input: payload } });
    } else {
      checkoutLineAdd({ variables: payload });
    }
  };

  return (
    <>
      <div className="char-count">Here's your book</div>
      <>
        {!loading && prevewData && (
          <FlippingBook previewData={prevewData as PreviewBookType[]} />
        )}
        <div className="form-buttons preview-book-btn">
          <CustomButton title="Add to cart" onClick={onCheckout} />
        </div>
      </>
    </>
  );
};
