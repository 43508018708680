import { CustomImage } from "@Components/CustomImage";
import "./SubThumbnail.scss";

export const SubThumbnail = () => {
  return (
    <div className="sub-thumbnail">
      <div className="wrap">
        <div style={{ flex: 1 }}>
          <h5 className="sub-thumbnail-title">Teaching Reading is Easy with</h5>
          <p className="sub-thumbnail-desc">
            It is with immense pleasure and pride that I extend my warmest
            congratulations to you on being honored with the award for the Best
            Homeschool Curriculum
          </p>
        </div>
        <CustomImage className="sub-thumbnail-img" />
      </div>
    </div>
  );
};
