import { ArrowRightIcon } from "@Assets/icons";
import { ParamEnum } from "@Types";
import { useQuery } from "@apollo/client";
import {
  GetCheckoutDetailDocument,
  GetCheckoutDetailQuery,
  GetCheckoutDetailQueryVariables,
} from "@generated";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Checkout.scss";
import { ItemType, OrderSumarry } from "./components/OrderSummary";
import { StripeComponent } from "./components/StripeComponent";
import { UpdateCheckoutForm } from "./components/UpdateCheckoutForm";

const baseUrl = process.env.REACT_APP_FONT_STORE_URL;
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const Checkout: React.FC = () => {
  const [secretKey, setSecretKey] = useState<string>();
  const [isComplete, setIsComplete] = useState<boolean>();

  const navigate = useNavigate();
  const params = useParams();

  const variables: GetCheckoutDetailQueryVariables = {
    token: params[ParamEnum.checkout_token],
  };

  const { loading, error, data } = useQuery(GetCheckoutDetailDocument, {
    onCompleted(data) {
      console.log("[checkout Detail]", data.checkout);
    },
    variables,
  });

  if (loading) return <>Loading...</>;

  const onClickBack = () => {
    navigate("/home");
  };

  const onCheckoutComplete = (secertKey: string) => {
    setIsComplete(true);
    setSecretKey(secertKey);
  };

  const lineItem = data?.checkout?.lines;
  const lineItemProps =
    lineItem &&
    (lineItem.map((item) => ({
      lineItemId: item.id,
      name: item.variant.product.name,
      quantity: item.quantity,
      currency: item.unitPrice.gross.currency,
      unitPrice: item.unitPrice.gross.amount,
      totalPrice: item.totalPrice.gross.amount,
    })) as ItemType[]);

  return (
    <div className="checkout-page-wrap">
      <div className="form-wrap">
        <p onClick={onClickBack} className="form-wrap-back">
          <span>Back to home page</span>
          <ArrowRightIcon />
        </p>
        {!isComplete && (
          <UpdateCheckoutForm
            data={data as GetCheckoutDetailQuery}
            onComplete={onCheckoutComplete}
          />
        )}
        {isComplete && (
          <StripeComponent
            clientSecret={secretKey as string}
            publishableKey={stripePublicKey as string}
            returnUrl={`${baseUrl}/order/${data?.checkout?.token}`}
          />
        )}
      </div>
      <OrderSumarry
        totalItem={lineItem?.length as number}
        currency={data?.checkout?.totalPrice.currency as string}
        totalPrice={data?.checkout?.totalPrice.gross.amount as number}
        items={lineItemProps}
      />
    </div>
  );
};
