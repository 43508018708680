import React, { FC } from "react";
import "./HiddenMenu.scss";
import { CustomModal } from "@Components/CustomModal";
import LoginSignUp from "../LoginSignUp/LoginSignUp";
import Languages from "../Languages/Languages";
import HiddenMenuList from "./HiddenMenuList/HiddenMenuList";

interface PT {
  isOpenHiddenMenu: boolean;
  onClose: () => void;
}

const HiddenMenu: FC<PT> = ({ isOpenHiddenMenu, onClose }) => {
  return (
    <CustomModal
      isShowing={isOpenHiddenMenu}
      onCancel={onClose}
      className="hidden-menu"
      isDrawer
    >
      <LoginSignUp />
      <Languages />
      <HiddenMenuList />
    </CustomModal>
  );
};

export default React.memo(HiddenMenu);
