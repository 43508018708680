import React from "react";
import "./Copyright.scss";

export const Copyright: React.FC = () => {
  return (
    <div className="copyright">
      <div className="copyright-text">
        Copyright, Bookhouse. All rights reserved.
      </div>
      <div className="copyright-text">Terms & Conditions</div>
    </div>
  );
};
