import React from "react";
import "./SaleorApp.scss";

export const SaleorApp: React.FC = () => {
  return (
    <div className="saleor-app-wrap">
      <p>Wellcome to SKYBOOK SALEOR APP !</p>
      <p>This app custom to comunicate with saleor</p>
      <p>Feature like payment, deivery, ... will coming soon!</p>
    </div>
  );
};
//
