import {
  BestSellers,
  Footer,
  GreenLine,
  Header,
  MainThumbnail,
  NewProducts,
  SubThumbnail,
} from "@Containers";
import "./Home.scss";

export const Home = () => {
  return (
    <>
      <Header />
      <div className="page-body">
        <MainThumbnail />
        <BestSellers />
        <GreenLine />
        <NewProducts />
        <SubThumbnail />
      </div>
      <Footer activeSubcribe={true} />
    </>
  );
};
