import React from "react";
import "./MainThumbnail.scss";
import MainThumbnailLeft from "./components/MainThumbnailLeft/MainThumbnailLeft";
import MainThumbnailRight from "./components/MainThumbnailRight/MainThumbnailRight";

export const MainThumbnail: React.FC = () => {
  return (
    <div className="main-thumbnail">
      <div className="wrap">
        <div className="main-thumbnail-layout">
          <MainThumbnailLeft />
          <MainThumbnailRight />
        </div>
      </div>
    </div>
  );
};
