import { Footer } from "@Containers/Footer";
import { Header } from "@Containers/Header";
import { sliderActions, useAppDispatch } from "@Redux";
import { useEffect } from "react";
import { MainDetail } from "./components/MainDetail/MainDetail";

export const Detail: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(sliderActions.reset());
    };
  }, []);

  return (
    <>
      <Header />
      <MainDetail />
      <Footer activeSubcribe={true} />
    </>
  );
};
