import { CustomInput } from "@Components/CustomInput";
import React, { useEffect, useState } from "react";
import "./InputForm.scss";
import { CharactorProperty } from "@Services";
import { useRenderHelper } from "@Hooks";
import { FormButtons } from "@Components";

interface Props extends CharactorProperty {}

export const InputForm: React.FC<Props> = (props: Props) => {
  const {
    activeNext,
    buttonTitle,
    inputValue,
    onChangeValue,
    onNextStep,
    onPrevStep,
  } = useRenderHelper(props);

  const [errorMess, setErrorMess] = useState<string>();
  const [isInit, setInit] = useState<boolean>(true);

  useEffect(() => {
    if (isInit) setInit(false);
    if (inputValue && inputValue.length >= 8 && !isInit)
      setErrorMess(
        "We’re sorry, the Name must be less than 8 characters length"
      );
    else setErrorMess("");
  }, [inputValue]);

  return (
    <>
      <div className="custom-form-title">{props.title}</div>
      <div className="custom-form-desc">
        What should they be called in this story? (e.g. Daddy, Lucy Smith,
        Grandpa John, Mrs. Khan)
      </div>
      <CustomInput
        onChange={onChangeValue}
        className="custom-form-input"
        placeholder="Please enter your custom value"
        errorMess={errorMess}
      />
      <FormButtons
        onNext={onNextStep}
        onBack={onPrevStep}
        nextTitle={buttonTitle?.nextTitle as string}
        prevTitle={buttonTitle?.prevTitle as string}
        disabled={!activeNext || !!errorMess}
      />
    </>
  );
};
