import React from "react";
import "./Languages.scss";
import { CustomDropdown } from "@Components/CustomDropdown";
import useGetLanguage from "./hooks/useGetLanguage";

const Languages = () => {
  const { languages, selectedLanguage, changeLanguage } = useGetLanguage();

  return (
    <CustomDropdown
      title="Chọn ngôn ngữ"
      options={languages}
      selected={selectedLanguage}
      onChange={changeLanguage}
      className="language-button"
    />
  );
};

export default React.memo(Languages);
