import { ReactNode } from "react";

export interface SelectItemType {
  value: string;
  label: ReactNode;
}

export enum ParamEnum {
  // id reference product of saleor
  product_id = "product_id",
  checkout_token = "checkout_token",
  book_id = "book_id",
}

export enum FieldType {
  INPUT = "INPUT",
  SELECT = "SELECT",
  SELECT_IMG = "SELECT_IMG",
  SELECT_COLOR = "SELECT_COLOR",
}

export enum GenderEnum {
  male = "male",
  female = "female",
}

export enum PersonalizePhase {
  START = "START",
  PROCESSING = "CHAR_PROCESS",
  COMPLETE = "COMPLETE",
}
