import { RetriveImage } from "@Components";
import React, { useState } from "react";
import { DedicatedModal } from "../../DedicatedModal/DedicatedModal";

// Type definition for the Page component props
interface PageProps {
  page: number;
  url: string;
  editDedications?: boolean;
}

// Page component with forwardRef and TypeScript annotations
export const Page = React.forwardRef<HTMLDivElement, PageProps>(
  (props, ref) => {
    const [isOpenMadal, setIsOpenModal] = useState<boolean>(false);
    const onCloseModal = () => {
      setIsOpenModal(false);
    };

    return (
      <div ref={ref} className="flipping-page">
        <RetriveImage path={props.url} alt={props.page.toString()} />
        {props?.editDedications ? (
          <button
            className="dedicate-btn"
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Write Dedications
          </button>
        ) : (
          <></>
        )}
        <DedicatedModal
          pageNumber={props.page}
          onClose={onCloseModal}
          isOpen={isOpenMadal}
        />
      </div>
    );
  }
);
Page.displayName = "Page";

// Page component with forwardRef and TypeScript annotations
export const CoverPage = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref}>
      <RetriveImage path="common/cover-page.png" alt="Cover Page" />
    </div>
  );
});
CoverPage.displayName = "CoverPage";
