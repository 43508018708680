import { serviceSaleorInstance } from "../axios.instant";
import { CollectionsResponse, ProductQuery, ProductsResponse } from "./types";

export class SaleorAppApis {
  static async getCollections(
    onSuccess?: (data: any) => void,
    onError?: () => void
  ): Promise<CollectionsResponse> {
    const data = (await serviceSaleorInstance.get(
      `/products/collections`
    )) as CollectionsResponse;
    console.log("[getCollections] data", data);
    if (data.errorCode == 200) {
      onSuccess?.(data);
    } else {
      onError?.();
    }
    return data;
  }

  static async getProducts(
    query?: ProductQuery,
    onSuccess?: (data: any) => void,
    onError?: () => void
  ): Promise<ProductsResponse> {
    const queryString =
      query &&
      Object.entries(query)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

    const data = (await serviceSaleorInstance.get(
      `/products?${queryString}`
    )) as ProductsResponse;
    console.log("[getProducts] data", data);
    if (data.errorCode == 200) {
      onSuccess?.(data);
    } else {
      onError?.();
    }
    return data;
  }
}
