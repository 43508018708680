import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "@Components/CustomButton";

export type ItemType = {
  lineItemId: string;
  name: string;
  quantity: number;
  currency: string;
  unitPrice: number;
  totalPrice: number;
};

interface Props {
  checkoutId: string;
  checkoutToken: string;
  totalItem: number;
  currency: string;
  totalPrice: number;
  items?: ItemType[];
}

export const Bill: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  const onCheckout = () => {
    navigate(`/checkout/${props.checkoutToken}`);
  };

  return (
    <div className="bill-wrap">
      <p className="bill-title">Order summary</p>
      <p className="bill-desc">Subtotal ( {props.totalItem} item )</p>
      {props.items?.map((item) => (
        <div className="bill-item">
          <p style={{ maxWidth: "150px" }}>{item.name}</p>
          <p style={{ margin: "auto 20px", width: "50px" }}>
            X {item.quantity}
          </p>
          <p>
            ${item.totalPrice} {item.currency}
          </p>
        </div>
      ))}
      <div className="bill-summary">
        <p>Order summary</p>
        <p style={{ color: "#FA7436" }}>
          ${props.totalPrice} {props.currency}
        </p>
      </div>
      <CustomButton
        disabled={props.totalItem < 1}
        title="Check out"
        onClick={onCheckout}
        className="checkout-btn"
      />
    </div>
  );
};
