import React from "react";
import "./Help.scss";

const helpArray = [
  "Help & FAQs",
  "Delivery Info",
  "Privecy Policy",
  "Returns Policy",
  "Sitemap",
  "UGC Terms & Conditions",
  "Wonderbly’s Accessibility Statement",
  "Wonderbly’s Accessibility Statement",
];

export const Help: React.FC = () => {
  return (
    <div>
      <h6 className="help-title">Help and Support</h6>
      {helpArray.map((a, i) => (
        <p key={i} className="help-item">
          {a}
        </p>
      ))}
    </div>
  );
};

export default React.memo(Help);
