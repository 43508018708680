import { ReactNode } from "react";

export interface MainRoutesType {
  path: string;
  element: ReactNode;
}

export enum BasicMainRouteEnum {
  "home" = "/",
  "detail" = "detail",
  "personalized" = "personalized",
  "preview-character" = "preview-character",
  "preview-book" = "preview-book",
  "checkout" = "checkout",
  "cart" = "cart",
  "products" = "products",
  "order" = "order",
  "saleor-app" = "saleor-app",
}

export const MainRouteEnum = {
  ...BasicMainRouteEnum,
};
