import React from "react";
import { UseFormRegister } from "react-hook-form";
import { Option } from "@Types";
import "./SelectForm.scss";

interface Props {
  lable: string;
  name: string;
  required?: boolean;
  helperText?: string;
  register: UseFormRegister<any>;
  options: Option[];
}

export const SelectForm: React.FC<Props> = ({
  name,
  lable,
  required,
  helperText,
  options,
  register,
}) => {
  return (
    <div className="select-form-warp">
      <label className="select-form-lable">
        {lable}
        {required && <span>*</span>}
      </label>
      <select
        className="select-form-item"
        {...register(name, {
          required: {
            value: required as boolean,
            message: `${lable} is require!`,
          },
        })}
      >
        {options.map((option) => (
          <option value={option.key}>{option.value}</option>
        ))}
      </select>
      {helperText && (
        <span className="select-form-helperText">{helperText}</span>
      )}
    </div>
  );
};
