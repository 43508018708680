import { InputForm } from "@Components/InputForm";
import { SelectForm } from "@Components/SelectForm";
import { Field, Option } from "@Types";
import { useMutation } from "@apollo/client";
import {
  CheckoutBillingAddressUpdateDocument,
  CheckoutCompleteDocument,
  CheckoutEmailUpdateDocument,
  CheckoutPaymentCreateDocument,
  CountryCode,
  GetCheckoutDetailQuery,
} from "@generated";
import { useState } from "react";
import { useForm } from "react-hook-form";
import uuid from "react-uuid";
import { ADDRESS_FIELDS } from "./constants/form.const";
import { IUpdateCheckoutFormInput } from "./types/form.type";
import { toast } from "react-toastify";
import { CustomButton } from "@Components";

interface Props {
  data: GetCheckoutDetailQuery;
  onComplete?: (secertKey: string) => void;
}

export const UpdateCheckoutForm: React.FC<Props> = (props: Props) => {
  const { checkout } = props.data;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateCheckoutFormInput>({
    defaultValues: {
      email: checkout?.email || "",
      firstName: checkout?.billingAddress?.firstName,
      lastName: checkout?.billingAddress?.lastName,
      streetAddress1: checkout?.billingAddress?.streetAddress1,
      streetAddress2: checkout?.billingAddress?.streetAddress2,
      postalCode: checkout?.billingAddress?.postalCode,
      country: checkout?.billingAddress?.country.code as CountryCode,
      city: checkout?.billingAddress?.city,
    },
  });

  const [checkoutUpdateBillingAddress, { error: updateBillAddressError }] =
    useMutation(CheckoutBillingAddressUpdateDocument, {
      onCompleted: (data) => {
        toast.success("Checkout update address success!");
        return;
      },
      onError: (err) => {
        toast.error("Checkout update address error!");
      },
    });

  const [checkoutUpdateEmail, { loading: updateEmailLoading }] = useMutation(
    CheckoutEmailUpdateDocument,
    {
      onCompleted: (data) => {
        toast.success("Checkout update email success!");
        return;
      },
      onError: (err) => {
        toast.error("Checkout update email error!");
      },
    }
  );

  const [checkoutPaymetCreate, { loading: checkoutPaymentLoading }] =
    useMutation(CheckoutPaymentCreateDocument, {
      onCompleted: (data) => {
        checkoutComplete({
          variables: { token: data.checkoutPaymentCreate?.checkout?.token },
        });
        toast.success("Create checkout payment success!");
        return;
      },
      onError: (err) => {
        toast.error("Create checkout payment error!");
      },
    });

  const [checkoutComplete, { loading: CheckoutCompleteLoading }] = useMutation(
    CheckoutCompleteDocument,
    {
      onCompleted: (data) => {
        const confirmationData = data.checkoutComplete?.confirmationData;
        const secretKey = JSON.parse(confirmationData)?.client_secret;
        props.onComplete?.(secretKey);
        toast.success("Checkout complete!");
      },
      onError: (err) => {
        toast.error("Checkout complete fail!");
      },
    }
  );

  const onSubmit = async (data: IUpdateCheckoutFormInput) => {
    const { email, ...res } = data;
    const [addressUpdate, mailUpdate] = await Promise.all([
      checkoutUpdateBillingAddress({
        variables: {
          token: checkout?.token,
          billingAddress: {
            ...res,
          },
          validationRules: {
            checkRequiredFields: true,
          },
        },
      }),
      checkoutUpdateEmail({
        variables: {
          token: checkout?.token,
          email,
        },
      }),
    ]);

    const addressErrror =
      addressUpdate.data?.checkoutBillingAddressUpdate?.errors;
    const updateEmailError = mailUpdate.data?.checkoutEmailUpdate?.errors;
    const hasError =
      (addressErrror?.length && addressErrror.length > 0) ||
      (updateEmailError && updateEmailError?.length > 0);

    console.log({ addressErrror, updateEmailError });
    console.log(hasError);
    if (hasError) {
      toast.error("Something wen wrong!");
    } else {
      await checkoutPaymetCreate({
        variables: {
          token: checkout?.token,
          input: {
            amount: checkout?.totalPrice.gross.amount,
            gateway: "saleor.payments.stripe",
            token: uuid(),
          },
        },
      });
    }
  };

  const renderField = (f: Field) => {
    switch (f.type) {
      case "text":
        return (
          <InputForm
            lable={f.label as string}
            name={f.name}
            register={register}
            required={f.required}
            placeHolder={f.placeholder}
            helperText={
              errors[f.name as keyof IUpdateCheckoutFormInput]?.message || ""
            }
          />
        );
      case "select":
        return (
          <SelectForm
            lable={f.label as string}
            name={f.name}
            register={register}
            options={f.options as Option[]}
            helperText={
              errors[f.name as keyof IUpdateCheckoutFormInput]?.message || ""
            }
          />
        );
      default:
        break;
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      {ADDRESS_FIELDS.map((f: Field) => renderField(f))}
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <CustomButton title={"Go next"} className="checkout-btn" />
      </div>
    </form>
  );
};
