import { CharactorProperty } from "@Services";
import { FieldType } from "@Types";
import "./CustomForm.scss";
import { InputForm } from "./components/InputForm/InputForm";
import { SellectForm } from "./components/SelecForm/SellectForm";
import { SelectColorForm } from "./components/SelectColorForm/SelectColorForm";
import { SelectImgForm } from "./components/SelectImgForm.tsx/SelectImgForm";

interface Props extends CharactorProperty {}

export const CustomForm: React.FC<Props> = (props: Props) => {
  const renderForm = () => {
    switch (props.type) {
      case FieldType.INPUT:
        return <InputForm {...props} />;
      case FieldType.SELECT:
        return <SellectForm {...props} />;
      case FieldType.SELECT_COLOR:
        return <SelectColorForm {...props} />;
      case FieldType.SELECT_IMG:
        return <SelectImgForm {...props} />;
      default:
        return <></>;
    }
  };

  return <div className="custom-form">{renderForm()}</div>;
};
