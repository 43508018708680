import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "@Components/CustomButton";
import { CustomImage } from "@Components/CustomImage";
import { MainRouteEnum } from "@Types";
import { GetProductDetailQuery } from "@generated";
import "./DetailDescBody.scss";

export interface Props {
  data: GetProductDetailQuery;
}

export const DetailDescBody: React.FC<Props> = (props: Props) => {
  const { product } = props.data;
  const navigate = useNavigate();

  const bookId = product?.metadata.find((data) => data.key == "bookId")?.value;

  const onClick = () => {
    if (!product) return;
    const navLink = `/${MainRouteEnum.personalized}/${product.id}/${bookId}`;
    navigate(navLink);
  };

  const description =
    JSON.parse(product && product.description)?.blocks[0]?.data?.text || "";

  const priceObj = product && product.pricing?.priceRange?.start?.gross;

  return (
    <div className="detail-des-body">
      <div className="detail-des-body-text">{description}</div>
      <div className="detail-des-body-list">
        <CustomImage className="detail-des-body-icon detail-des-body-gift" />
        Recommended for ages 0 to 3
      </div>
      <div className="detail-des-body-list">
        <CustomImage className="detail-des-body-icon detail-des-body-book" />
        Preview all 40+ pages before you buy
      </div>
      <div className="detail-des-body-list">
        <CustomImage className="detail-des-body-icon detail-des-body-air" />
        Ships worldwide within 48 hours
      </div>
      <div className="detail-des-body-price">
        From ${priceObj?.amount} {priceObj?.currency}
      </div>
      <CustomButton
        title="Personalize my book"
        onClick={onClick}
        className="detail-des-body-btn"
      />
    </div>
  );
};
