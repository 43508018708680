import React, { ReactNode, useEffect, useState } from "react";
import { useClickOutside } from "@Hooks";
import { useQuickClose } from "@Hooks";
import { SelectItemType } from "@Types";
import { CustomButton } from "@Components/CustomButton";
import "./CustomDropdown.scss";
import DropdownList from "./components/DropdownList/DropdownList";
import DropdownPlaceholder from "./components/DropdownPlaceholder/DropdownPlaceholder";

interface PT {
  title: string;
  options: SelectItemType[];
  selected: string | null;
  onChange: (e: string | null) => void;
  className?: string;
}

export const CustomDropdown: React.FC<PT> = ({
  title,
  options,
  selected,
  onChange,
  className,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState<ReactNode | null>(null);

  const onCLose = () => {
    setOpen(false);
  };

  useQuickClose(isOpen, onCLose);
  const { divRef } = useClickOutside(onCLose);

  useEffect(() => {
    const found = options.find((a) => a.value === selected);
    if (found) setSelectedLabel(found.label);
    else setSelectedLabel(null);
  }, [selected]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = (e: string | null) => {
    onChange(e);
    setOpen(false);
  };

  return (
    <div
      ref={divRef}
      className={`custom-dropdown${className ? " " + className : ""}`}
    >
      <CustomButton
        title={
          <DropdownPlaceholder title={title} selectedLabel={selectedLabel} />
        }
        onClick={handleToggle}
        className="dropdown-button"
      />
      <DropdownList
        isOpen={isOpen}
        handleClose={handleClose}
        options={options}
        selected={selected}
      />
    </div>
  );
};
