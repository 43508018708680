import { ErrorBoundary } from "@Components";
import { MainLayout } from "@Containers";
import { store } from "@Redux";
import {
  ApolloClient,
  ApolloProvider,
  DefaultOptions,
  InMemoryCache,
} from "@apollo/client";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Style/theme.scss";
import "./index.css";

const SALEOR_URL_GRAPHQL = process.env.REACT_APP_SALEOR_URL;
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const apoloClient = new ApolloClient({
  uri: SALEOR_URL_GRAPHQL,
  cache: new InMemoryCache(),
  defaultOptions,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <Provider store={store}>
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
          <ApolloProvider client={apoloClient}>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <MainLayout />
          </ApolloProvider>
        </CookiesProvider>
        ,
      </Provider>
    </BrowserRouter>
  </ErrorBoundary>
);
