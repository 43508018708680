import React from "react";

export const AddIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" rx="5" fill="#E5E5E5" />
      <path
        d="M19.834 13.1667H14.834C14.834 11.9311 15.5326 8.52016 14.5899 7.57742C14.4336 7.42114 14.2217 7.33334 14.0007 7.33334C13.7796 7.33334 13.5677 7.42114 13.4114 7.57742C13.2551 7.7337 13.1673 7.94566 13.1673 8.16668V13.1667H8.16732C7.9463 13.1667 7.73434 13.2545 7.57806 13.4108C7.42178 13.567 7.33398 13.779 7.33398 14C7.33398 14.221 7.42178 14.433 7.57806 14.5893C7.73434 14.7455 7.9463 14.8333 8.16732 14.8333H13.1673V19.8333C13.1673 20.0544 13.2551 20.2663 13.4114 20.4226C13.5677 20.5789 13.7796 20.6667 14.0007 20.6667C14.2217 20.6667 14.4336 20.5789 14.5899 20.4226C14.7462 20.2663 14.834 20.0544 14.834 19.8333V14.8333H19.834C20.055 14.8333 20.267 14.7455 20.4232 14.5893C20.5795 14.433 20.6673 14.221 20.6673 14C20.6673 13.779 20.5795 13.567 20.4232 13.4108C20.267 13.2545 20.055 13.1667 19.834 13.1667Z"
        fill="#444444"
      />
    </svg>
  );
};
