import React from "react";
import "./TopBar.scss";
import Search from "../Search/Search";
import TopRightFeature from "../TopRightFeature/TopRightFeature";
import MainLogo from "../MainLogo/MainLogo";

const TopBar = () => {
  return (
    <div className="top-bar">
      <MainLogo />
      <Search />
      <TopRightFeature />
    </div>
  );
};

export default React.memo(TopBar);
