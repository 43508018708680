import React from "react";
import "./LoginSignUp.scss";
import { CustomButton } from "@Components/CustomButton";
import { Bounce, toast } from "react-toastify";

const LoginSignUp = () => {
  const onLogin = () => {
    console.log("Login");
  };

  const onSignUp = () => {
    console.log("Sign up");
  };
  return (
    <div className="login-signup">
      <CustomButton title="Login" onClick={onLogin} className="login-btn" />
      <CustomButton title="Sign up" onClick={onSignUp} className="signup-btn" />
    </div>
  );
};

export default React.memo(LoginSignUp);
