import { cartReducer } from "./carts/carts.slice";
import { personalizeReducer } from "./personalize";
import { previewReducer } from "./preview-products/preview-product.slice";
import { sliderReducer } from "./sliders";

export const rootReducers = {
  slider: sliderReducer,
  cart: cartReducer,
  personalize: personalizeReducer,
  preview: previewReducer,
};
