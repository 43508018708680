import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "@Components/CustomButton";
import { MainRouteEnum } from "@Types";
import "./BestSellerItem.scss";

interface Props {
  productName: string;
  description: string;
  thumbnail: string;
  productId: string;
}

const BestSellerAbleItem: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/${MainRouteEnum.detail}/${props.productId}`);
  };

  return (
    <div className="best-seller-item">
      <div>
        <div className="best-sellers-img">
          <img style={{ width: "100%" }} src={props.thumbnail} />
        </div>
        <h4 className="best-seller-title">{props.productName}</h4>
        <p className="best-sellers-desc">{props.description}</p>
      </div>
      <CustomButton
        title="Personalize"
        onClick={onClick}
        className="best-seller-btn"
      />
    </div>
  );
};

export default React.memo(BestSellerAbleItem);
