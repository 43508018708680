import React, { useState } from "react";
import "./HiddenMenuButton.scss";
import { CustomButton } from "@Components/CustomButton";
import { CustomImage } from "@Components/CustomImage";
import HiddenMenu from "../HiddenMenu/HiddenMenu";

const HiddenMenuButton = () => {
  const [isOpenHiddenMenu, setOpenHiddenMenu] = useState<boolean>(false);

  const onOpen = () => {
    setOpenHiddenMenu(true);
  };

  const onClose = () => {
    setOpenHiddenMenu(false);
  };

  return (
    <>
      <CustomButton
        title={<CustomImage className="hidden-menu-logo" />}
        onClick={onOpen}
        className="hidden-menu-btn"
      />
      <HiddenMenu isOpenHiddenMenu={isOpenHiddenMenu} onClose={onClose} />
    </>
  );
};

export default React.memo(HiddenMenuButton);
