import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import "./CustomModal.scss";
import { useQuickClose } from "@Hooks";

interface PT {
  children: ReactNode;
  isShowing: boolean;
  onCancel?: () => void;
  className?: string;
  isDrawer?: boolean;
}

export const CustomModal: React.FC<PT> = ({
  children,
  isShowing,
  onCancel,
  className,
  isDrawer,
}) => {
  const onClose = () => {
    onCancel?.();
  };

  const stopClose = (event: any) => {
    event.stopPropagation();
  };

  useQuickClose(isShowing, onClose);

  return isShowing ? (
    ReactDOM.createPortal(
      <React.Fragment>
        <div className="custom-modal-overlay" />
        <div
          onClick={onClose}
          className={`custom-modal-wrapper ${
            isDrawer ? "custom-modal-drawer" : "custom-modal-center"
          }`}
          aria-modal
          aria-hidden
          tabIndex={-1}
          role="dialog"
        >
          <div
            className={`custom-modal${className ? " " + className : ""}`}
            onClick={stopClose}
          >
            {children}
          </div>
        </div>
      </React.Fragment>,
      document.body
    )
  ) : (
    <></>
  );
};
