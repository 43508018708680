import { CollectionsResponse, SaleorAppApis } from "@Services";
import { GetCollectionsQuery } from "@generated";
import { useEffect, useState } from "react";

export const useGetCollections = () => {
  const [collections, setCollections] = useState<CollectionsResponse>();

  useEffect(() => {
    const onSuccess = (data: CollectionsResponse) => {
      setCollections(data);
    };

    SaleorAppApis.getCollections(onSuccess);
  }, []);

  return { collections };
};
