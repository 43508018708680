import React from "react";
import "./TopRightFeature.scss";
import LoginSignUp from "../LoginSignUp/LoginSignUp";
import IconFeature from "../IconFeature/IconFeature";
import Languages from "../Languages/Languages";

const TopRightFeature = () => {
  return (
    <div className="top-right-feature">
      <LoginSignUp />
      <IconFeature />
      <Languages />
    </div>
  );
};

export default React.memo(TopRightFeature);
