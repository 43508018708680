import { useQuery } from "@apollo/client";
import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "@Assets/icons";
import { cartActions } from "@Redux/carts";
import {
  GetCheckoutDetailDocument,
  GetCheckoutDetailQueryVariables,
} from "@generated";
import { Footer } from "@Containers/Footer";
import { Header } from "@Containers/Header";
import "./Cart.scss";
import { Bill, ItemType } from "./components/Bill";
import { CartItem } from "./components/CartItem";

export const Cart: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cookies] = useCookies(["checkoutToken"]);

  if (!cookies.checkoutToken) return <div>Cart is empty...</div>;

  const variables: GetCheckoutDetailQueryVariables = {
    token: cookies.checkoutToken,
  };

  const { loading, error, data } = useQuery(GetCheckoutDetailDocument, {
    variables,
    onCompleted(data) {
      console.log(data);
      dispatch(
        cartActions.setCart({
          checkoutId: data.checkout?.id as string,
          itemCount: data.checkout?.lines.length as number,
          checkoutToken: data.checkout?.token,
        })
      );
    },
  });

  if (loading) return <></>;

  const onClickBack = () => {
    navigate("./home");
  };

  const lineItem = data?.checkout?.lines;

  const lineItemProps =
    lineItem &&
    (lineItem.map((item) => ({
      lineItemId: item.id,
      name: item.variant.product.name,
      quantity: item.quantity,
      currency: item.unitPrice.gross.currency,
      unitPrice: item.unitPrice.gross.amount,
      totalPrice: item.totalPrice.gross.amount,
    })) as ItemType[]);

  return (
    <>
      <Header />
      <div className="page-body">
        <div className="cart-page-wrap">
          <div className="cart-item-wrap">
            <div className="cart-item-header">
              <p className="cart-item-header__title">
                Your Cart ( {lineItem && lineItem.length} )
              </p>
              <p onClick={onClickBack} className="cart-item-header__back">
                <span>Continue Shopping</span>
                <ArrowRightIcon />
              </p>
            </div>
            {lineItem &&
              lineItem.map((item) => (
                <CartItem
                  key={item.id}
                  checkoutToken={data.checkout?.token as string}
                  checkoutLineId={item.id}
                  unitPrice={item.unitPrice.gross.amount}
                  currency={item.unitPrice.gross.currency}
                  totalPrice={item.totalPrice.gross.amount}
                  productThumnail={
                    item.variant.product.media?.[0].url as string
                  }
                  productName={item.variant.product.name}
                  quantity={item.quantity}
                />
              ))}
          </div>
          <Bill
            checkoutToken={data?.checkout?.token}
            checkoutId={data?.checkout?.id as string}
            totalItem={lineItem?.length as number}
            currency={data?.checkout?.totalPrice.currency as string}
            totalPrice={data?.checkout?.totalPrice.gross.amount as number}
            items={lineItemProps}
          />
        </div>
      </div>
      <Footer activeSubcribe={false} />
    </>
  );
};
