import { ProductNodeFragment } from "@generated";
import React from "react";
import { useGetProducts } from "@Hooks";
import BestSellerItem from "../BestSellers/BestSellerItem/BestSellerItem";
import "./NewProducts.scss";

export const NewProducts: React.FC = () => {
  const { products } = useGetProducts({});
  const productNodes = products?.data?.data.products?.edges || [];

  // render product cart item
  const productRender = (product: ProductNodeFragment) => {
    const thumbnail = product.node.media?.[0].url as string;
    const description =
      JSON.parse(product.node.description)?.blocks[0]?.data?.text || "";

    return (
      <BestSellerItem
        key={product.node.id}
        productName={product.node.name}
        description={description}
        thumbnail={thumbnail}
        productId={product.node.id}
      />
    );
  };

  return (
    <div className="new-product">
      <div className="wrap">
        <h5 className="new-product-title">New Book For You</h5>
        <div className="new-product-array">
          {productNodes.map((product) => productRender(product))}
        </div>
      </div>
    </div>
  );
};
