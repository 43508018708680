import React from "react";

export const EditIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0808 2.35787C12.3083 2.1304 12.3083 1.76293 12.0808 1.53546L10.7158 0.170599C10.6068 0.0613795 10.4589 0 10.3046 0C10.1503 0 10.0023 0.0613795 9.89333 0.170599L8.75 1.31381L10.9375 3.50109L12.0808 2.35787ZM2.33333 7.72982L8.16667 1.89709L10.3542 4.08436L4.52083 9.91709H2.33333V7.72982ZM3.5 8.75054H4.03667L8.70333 4.08436L8.16667 3.54775L3.5 8.21393V8.75054ZM14 11.6669H0V14H14V11.6669Z"
        fill="#FA7436"
      />
    </svg>
  );
};
