import React, { ReactNode } from "react";
import "./DropdownPlaceholder.scss";

interface PT {
  title: string;
  selectedLabel: ReactNode | null;
}

const DropdownPlaceholder: React.FC<PT> = ({ title, selectedLabel }) => {
  return (
    <div
      className={`dropdown-placeholder ${
        selectedLabel
          ? "dropdown-placeholder-selected"
          : "dropdown-placeholder-unselected"
      }`}
    >
      {selectedLabel ? selectedLabel : title}
      <div className="dropdown-placeholder-icon"></div>
    </div>
  );
};

export default React.memo(DropdownPlaceholder);
