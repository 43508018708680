import React from "react";
import "./CharCount.scss";
import { useSelector } from "react-redux";
import { personalizeSelector } from "@Redux";

export const CharCount: React.FC = () => {
  const personalizeState = useSelector(personalizeSelector);

  return (
    <div className="char-count">
      Character {personalizeState.currCharIndex} out of{" "}
      {personalizeState.totalCharCount}
    </div>
  );
};
