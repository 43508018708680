import { FC, useEffect, useState } from "react";

interface P {
  path: string;
  alt?: string;
  maxRetries?: number;
}

export const RetriveImage: FC<P> = ({ path, alt, maxRetries = 30 }) => {
  const minIoUrl = process.env.REACT_APP_MINIO_URL;
  const [currentSrc, setCurrentSrc] = useState<string>();
  const [retryCount, setRetryCount] = useState<number>(0);

  useEffect(() => {
    setCurrentSrc(`${minIoUrl}/${path}`);
    if (retryCount > 0 && retryCount <= maxRetries) {
      const timer = setTimeout(() => {
        console.log(`Retrying to load image. Attempt: ${retryCount}`);
        setCurrentSrc(`${minIoUrl}/${path}?retry=${retryCount}`);
      }, 1000); // Retry after 1 second

      return () => clearTimeout(timer);
    }
  }, [retryCount, path, maxRetries]);

  const handleError = () => {
    if (retryCount < maxRetries) {
      setRetryCount(retryCount + 1);
    } else {
      console.log("Max retries reached. Could not load the image.");
    }
  };

  return (
    <img
      style={{ width: "100%" }}
      src={currentSrc}
      alt={alt}
      onError={handleError}
    />
  );
};
