import { useMutation } from "@apollo/client";
import {
  AddIcon,
  BookIcon,
  CloseIcon,
  EditIcon,
  StarIcon,
  SubtractIcon,
} from "@Assets/icons";
import { CheckoutLineDeleteDocument } from "@generated";
import { useDispatch } from "react-redux";
import { cartActions } from "@Redux/carts";
import { toast } from "react-toastify";

interface Props {
  checkoutToken: string;
  checkoutLineId: string;
  unitPrice: number;
  currency: string;
  totalPrice: number;
  productThumnail: string;
  productName: string;
  quantity: number;
}

export const CartItem: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const [checkoutLineDelete, { loading: createCheckoutLOading }] = useMutation(
    CheckoutLineDeleteDocument,
    {
      onCompleted: (data) => {
        dispatch(cartActions.changeCount(-1));
        toast.success("Delete cart item Success!");
      },
      onError: (err) => {
        toast.success("Delete cart item Error!");
      },
    }
  );

  const onRemoveItem = () => {
    checkoutLineDelete({
      variables: {
        token: props.checkoutToken,
        linesIds: [props.checkoutLineId],
      },
    });
  };

  const onAddQuantity = () => {
    console.log(123);
  };

  return (
    <div className="cart-item">
      <img className="cart-item-thumnail" src={props.productThumnail} />
      <div className="cart-item-detail">
        <p className="cart-item-detail__title">{props.productName}</p>
        <div className="cart-item-detail__desc">
          <div className="cart-item-detail__desc-quantity">
            <SubtractIcon />
            <input type="number" value={props.quantity}></input>
            <AddIcon />
          </div>
          <p>X</p>
          <p>
            {props.unitPrice} {props.currency}
          </p>
        </div>
        <div className="cart-item-detail__desc-actions">
          <p>
            <EditIcon />
            <span>Edit book</span>
          </p>
          <p>
            <BookIcon />
            <span>Edit format</span>
          </p>
          <p>
            <StarIcon />
            <span>Add other extras</span>
          </p>
        </div>
      </div>
      <div className="cart-item-summary" onClick={onRemoveItem}>
        <CloseIcon />
        <p>
          {props.totalPrice} {props.currency}
        </p>
      </div>
    </div>
  );
};
