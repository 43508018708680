import React from "react";

export type ItemType = {
  lineItemId: string;
  name: string;
  quantity: number;
  currency: string;
  unitPrice: number;
  totalPrice: number;
};

interface Props {
  totalItem: number;
  currency: string;
  totalPrice: number;
  items?: ItemType[];
}

export const OrderSumarry: React.FC<Props> = (props: Props) => {
  return (
    <div className="order-wrap">
      <p className="order-title">Order summary</p>
      <p className="order-desc">Subtotal ( {props.totalItem} item )</p>
      {props.items?.map((item) => (
        <div className="order-item">
          <p>{item.name}</p>
          <p style={{ margin: "auto 20px" }}>X {item.quantity}</p>
          <p>
            ${item.totalPrice} {item.currency}
          </p>
        </div>
      ))}
      <div className="order-summary">
        <p>Order summary</p>
        <p>
          ${props.totalPrice} {props.currency}
        </p>
      </div>
    </div>
  );
};
