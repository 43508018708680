import { SelectItemType } from "@Types";
import { FC, useState } from "react";
import "./CustomRadio.scss";

interface PT {
  options: SelectItemType[];
  onChange: (e: string) => void;
  isImg?: boolean;
  needOuterCircle?: boolean;
}

export const CustomRadio: FC<PT> = ({
  options,
  onChange,
  isImg,
  needOuterCircle,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleChange = (value: any) => {
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <div className="custom-radio">
      {options.map((a, i) => (
        <div className={`circle-check${isImg ? " img-check" : ""}`} key={i}>
          <input
            className="custom-radio-input"
            type="radio"
            id={a.value}
            value={a.value}
            name={a.value}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            checked={selectedOption === a.value}
          />
          <label htmlFor={a.value} className="custom-radio-label">
            {a.label}
          </label>
          {needOuterCircle && (
            <div
              className="outer-circle"
              onClick={() => {
                handleChange(a.value);
              }}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};
