import FullHeader from "./components/FullHeader/FullHeader";
import MobileHeader from "./components/MobileHeader/MobileHeader";

export const Header = () => {
  return (
    <>
      <FullHeader />
      <MobileHeader />
    </>
  );
};
