import { personalizeSelector } from "@Redux";
import { Character, CharactorProperty } from "@Services";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Progress.scss";
import ProgressItem from "./ProgressItem/ProgressItem";

interface Props extends Character {}

export const Progress: React.FC<Props> = (props: Props) => {
  const charProperties = props.properties;
  const personalizeState = useSelector(personalizeSelector);

  const renderProgressItem = (property: CharactorProperty, index: number) => {
    const currentStep = personalizeState.currStep as number;
    const isActive = currentStep > property.step;
    const isCurrent = currentStep == property.step;
    const name = property.field.toLocaleLowerCase();

    return (
      <ProgressItem
        name={name}
        key={index}
        index={index}
        total={props.totalStep}
        isActive={isActive}
        isCurrent={isCurrent}
      />
    );
  };
  return (
    <div className="progress">
      {charProperties &&
        charProperties.map((item: CharactorProperty, index) =>
          renderProgressItem(item, index)
        )}
    </div>
  );
};
