import { serviceBookInstance } from "../axios.instant";
import {
  BookProperties,
  DedicatePagePayload,
  DedicatePageResponse,
  PrevewBookResponse,
  PreviewBookPayload,
} from "./types";

export class BookApis {
  static async getBookProperties(
    bookId: string,
    onSuccess?: (data: BookProperties) => void,
    onError?: () => void
  ): Promise<BookProperties> {
    const data = (await serviceBookInstance.get(
      `/${bookId}/get-properties`
    )) as BookProperties;
    console.log("[getBookProperties] data", data);
    if (data.errorCode == 200) {
      onSuccess?.(data);
    } else {
      onError?.();
    }
    return data;
  }

  static async previewProduct(
    bookId: string,
    body: PreviewBookPayload,
    onSuccess?: (data: PrevewBookResponse) => void,
    onError?: () => void
  ): Promise<PrevewBookResponse> {
    const data = (await serviceBookInstance.post(
      `/${bookId}/create-preview-book`,
      body
    )) as PrevewBookResponse;
    console.log("[previewProduct] data", data);
    if (data.errorCode == 200) {
      onSuccess?.(data);
    } else {
      onError?.();
    }
    return data;
  }

  static async dedicatePage(
    bookId: string,
    body: DedicatePagePayload,
    onSuccess?: (data: DedicatePageResponse) => void,
    onError?: () => void
  ): Promise<DedicatePageResponse> {
    const data = (await serviceBookInstance.post(
      `/${bookId}/custom-dedication`,
      body
    )) as DedicatePageResponse;
    console.log("[dedicatePage] data", data);
    if (data.errorCode == 200) {
      onSuccess?.(data);
    } else {
      onError?.();
    }
    return data;
  }
}
