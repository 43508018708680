import { CloseIcon } from "@Assets";
import { CustomModal } from "@Components";
import { previewActions } from "@Redux";
import {
  BookApis,
  DedicatePagePayload,
  DedicatePageResponse,
  DedicatePageType,
} from "@Services";
import { ParamEnum } from "@Types";
import React, { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import "./DedicatedModal.scss";

interface Props {
  pageNumber: number;
  isOpen: boolean;
  onClose?: () => void;
}

export const DedicatedModal: React.FC<Props> = ({
  isOpen,
  onClose,
  pageNumber,
}: Props) => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const bookId = params[ParamEnum.book_id] as string;

  const [value, setValue] = useState<string>();
  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const onDedicateSuccess = (data: DedicatePageResponse) => {
    dispatch(previewActions.dedicatePage(data.data as DedicatePageType));
    onClose?.();
  };

  const onSubmit = () => {
    const currentState = location.state;
    const payload: DedicatePagePayload = {
      properties: currentState,
      dedication: { page: pageNumber, content: value as string },
    };

    BookApis.dedicatePage(bookId, payload, onDedicateSuccess);
  };

  const onClickCancel = () => {
    onClose?.();
  };

  return (
    <>
      {isOpen && (
        <CustomModal onCancel={onClose} isShowing={true}>
          <div className="dedicate-modal">
            <div className="dedicate-modal-close" onClick={onClickCancel}>
              <CloseIcon />
            </div>
            <p className="dedicate-modal-title">Write your own message</p>
            <p className="dedicate-modal-desc">
              Note: Include your own dedication on this page. There's a 10-line
              limit (including blank lines) so keep it snappy.
            </p>
            <textarea
              className="dedicate-modal-textarea"
              name="content"
              style={{ width: "100%" }}
              value={value}
              rows={4}
              cols={50}
              maxLength={500}
              onChange={(e) => onChange(e)}
            />
            <button className="dedicate-modal-btn" onClick={onSubmit}>
              Submit
            </button>
          </div>
        </CustomModal>
      )}
    </>
  );
};
