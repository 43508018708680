import { GetProductDetailQuery } from "@generated";
import React from "react";
import { CustomRating } from "@Components/Rating";
import "./DetailDescFooter.scss";

const rateDesc =
  "“My granddaughter loves this book! It brought back wonderful memories from my childhood, and reading nursery rhymes with my children, too.” – Jessica";

export interface Props {
  data: GetProductDetailQuery;
}

export const DetailDescFooter: React.FC<Props> = (props: Props) => {
  const { product } = props.data;

  const rate = product?.rating as number;

  return (
    <div className="detail-des-body-rate">
      <div className="detail-des-body-rate-total">
        Rated {rate} out of 5
        <div className="detail-desc-rate">
          <CustomRating
            rating={product?.rating as number}
            total={5}
            interactive={false}
          />
        </div>
      </div>
      <div className="detail-des-body-rate-desc">{rateDesc}</div>
    </div>
  );
};
