import React from "react";
import { GetProductDetailQuery } from "@generated";
import "./DetailDescHeader.scss";
import { CustomRating } from "@Components/Rating";

interface Props {
  data: GetProductDetailQuery;
}

export const DetailDescHeader: React.FC<Props> = (props: Props) => {
  const { product } = props.data;
  return (
    <div className="detail-desc-header">
      <div className="detail-desc-tittle">{product?.name}</div>
      <CustomRating
        rating={product?.rating as number}
        total={5}
        interactive={false}
      />
    </div>
  );
};
